// src/Story/Second1.tsx
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./Second1.css";
import { StoryStatusDict } from "../../utils/DictionaryTypes";
import Cookies from "js-cookie";

interface DataItem {
  text: string;
  message: string;
  answer: string[];
}

const story1 = () => {
  return (
    <div>
      <p>館内アナウンスが響く。家の近くにある水族館だ。</p>
      <br></br>
      <p>休みの日には師匠が色んな場所に連れて行ってくれた。</p>
      <p>
        特に好きだったのがこの水族館。水の中を自由にキラキラと泳ぐ熱帯魚たちは、私のお気に入りだ。
      </p>
      <p>
        小さい頃の私は、あの魚のようになりたかった。水槽の中を泳ぐ魚たちはとてもカラフルで、それぞれ好きな色を身に纏っているように思えたからだ。
      </p>
      <br></br>
      <p>
        作曲に出会ったときも、私はきっと目をキラキラと輝かせていたのだろう。
      </p>
      <p>自分が感じたことを音楽にして、周りの人に伝えられるのが嬉しかった。</p>
      <br></br>
      <p>私は自分の色を持てたのだろうか。</p>
      <br></br>
    </div>
  );
};

const story2 = () => {
  return (
    <div>
      <p>
        音声ガイドを聞きながら作品の前で立ち尽くす私。次は美術館に来たようだ。
      </p>
      <br></br>
      <p>小学生の私にとって、美術館はすごい場所だった。</p>
      <p>
        「すごい」という言葉以外に、どう表現していいかわからなかった、というのが正しいのだろう。私自身が感じた言葉にならない感情を他人に伝えるには、あまりにも未熟だった。
      </p>
      <br></br>
      <p>
        昔の自分に伝えたい。美術館にある作品のように、表現するための手段は言葉だけではないと。あの時作曲という手段を知っていたら、子供の時に感じた純粋な気持ちを今再び体験することができたかもしれない。
      </p>
      <br></br>
    </div>
  );
};

const story3 = () => {
  return (
    <div>
      <p>愉快な音楽が懐かしさを感じさせる。今度は遊園地だ。</p>
      <br></br>
      <p>
        様々なアトラクション、それにちなんだ音楽、たくさんの楽しそうな人々。この場所にいるときは現実を忘れられる気がした。
      </p>
      <p>
        一日でどれだけたくさんのアトラクションに乗れるかに挑戦したこともあった。ここにいる時間が、家で遊んでいる時間よりも好きだった。
      </p>
      <br></br>
      <p>
        そこまで音楽が好きではなかったとき、「音楽を聴くだけで心が踊るなんて」とこぼしたら、師匠が私をここへ連れ出してくれた。
      </p>
      <br></br>
      <p>この場所で、音楽の作り出す空気感がどれほど大切かを実感させられた。</p>
      <p>
        そういう意味で、私が音楽を好きになれたのはこの遊園地のおかげでもある。
      </p>
      <img className="diary" src="/story/second/1-diary-4.png" alt="日記4" />
      <br></br>
      <img className="diary" src="/story/second/1-diary-5.jpg" alt="日記4" />
      <br></br>
    </div>
  );
};

const story4 = () => {
  return (
    <div>
      <p>
        音を立てながら、切符が改札機に吸い込まれていく。町探検の始まりに、私は胸を弾ませている。
      </p>
      <br></br>
      <p>
        切符を自分で買えたときは少し大人になった気分だった。記憶に残っているのは、たくさん行っていた水族館も、美術館も、遊園地も、思った以上に近かったことに驚いたからだろうか。それとも、あの町を訪れる多くの人たちの楽しそうな雰囲気のおかげか。両町ドーム前駅から親田駅の間には、たくさんの娯楽店が存在していた。今もまだ残っているのだろうか。
      </p>
      <br></br>
      <p>あの頃の思い出は、今でも私の中で宝石のように輝いている。</p>
      <p>
        師匠がたくさん連れ出してくれたおかげで、音楽そのものを好きになれたのだ。
      </p>
      <br></br>
      <p>こうした経験が、私を形作っているのかも知れない。</p>
      <br></br>
    </div>
  );
};

const second1DataList: DataItem[] = [
  {
    text: "",
    message:
      "通った道の形がパスワードになるようだ。パンフレットと日記を見て、パスワードを求めてほしい。",
    answer: ["ゆくえ"],
  },
  {
    text: "",
    message:
      "今回は、通った場所の頭文字を順に読むとパスワードになるようだ。パンフレットと日記を見て、パスワードを求めてほしい。",
    answer: ["こうこうせい"],
  },
  {
    text: "",
    message:
      "今回は、通った道に書かれている文字を読むとパスワードが導けるようだ。パンフレットと日記を見て、パスワードを求めてほしい。",
    answer: ["つーとーん"],
  },
  {
    text: "",
    message:
      "パスワードは3つの方法で導けるようだ。1つ目の方法では「ヒト」、2つ目の方法では「きおく」になるらしい。3つ目のパスワードを求めてほしい。",
    answer: ["りょうしん"],
  },
];

const Second1: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const storyStatus: StoryStatusDict = location.state?.storyStatus || {
    currentStatus: 2,
    maxStatus: 2,
    second1Status: 0,
    second2Status: 0,
    second3Status: 0,
    second4Status: 0,
    second5Status: 0,
    second6Status: 0,
    second7Status: 0,
    second8Status: 0,
    second9Status: 0,
    second10Status: 0,
    second11Status: 0,
    second12Status: 0,
    secondId: 0,
  };
  useEffect(() => {
    const state = Number(Cookies.get("rechord_second_1_state")) || 0;
    setSecond1State(state);
  }, []);

  const initialSecond1State = storyStatus["second1Status"] === 10 ? 10 : 0;
  const [second1State, setSecond1State] = useState(initialSecond1State);
  const [userAnswers, setUserAnswers] = useState<string[]>(
    Array(second1DataList.length).fill("")
  );
  const [correctAnswers, setCorrectAnswers] = useState<boolean[]>(
    Array(second1DataList.length).fill(false)
  );
  const [wrongMessage, setWrongMessage] = useState<string>("");

  const handleAnswerSubmit = (index: number, answer: string) => {
    if (second1DataList[index].answer.includes(answer.trim())) {
      setWrongMessage("");
      const updatedCorrectAnswers = correctAnswers.map((item, i) =>
        i === index ? true : item
      );
      setCorrectAnswers(updatedCorrectAnswers);
      if (index === second1DataList.length - 1) {
        setSecond1State(10); // 最後の問題に正解したら状態を10に設定
        Cookies.set("clear_rechord_second_1", "true", { expires: 36500 });
      } else {
        setSecond1State((prev) => prev + 1); // 正解したら次の問題へ
        Cookies.set("rechord_second_1_state", `${second1State + 1}`, {
          expires: 36500,
        });
      }
    } else {
      setWrongMessage("答えが正しくないようです");
    }
  };

  const toSecond = () => {
    storyStatus["currentStatus"] = 2;
    storyStatus["second1Status"] = second1State;
    navigate("/story/second", { state: { storyStatus } });
  };

  const toHint = () => {
    window.open(
      "https://anothervision.tokyo/special/rechord-web/rechord-hint.html",
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <div className="sec1-container">
      <br></br>
      <h2 className="text">2nd 幼少期の記憶2</h2>
      {second1DataList.slice(0, second1State + 1).map((data, index) => (
        <div key={index} className="sec1-main">
          {index === 1 && story1()}
          {index === 2 && story2()}
          {index === 3 && story3()}
          <h3 className="text">{data.message}</h3>
          {/* <img src={data.imgSrc} alt={`問題 ${index + 1}`} /> */}
          {index === 0 && (
            <div className="diary">
              <img src="/story/second/1-diary-1-1.png" alt="日記1-1" />
              <img src="/story/second/1-diary-1-2.png" alt="日記1-2" />
            </div>
          )}
          {index === 1 && (
            <div className="diary">
              <img src="/story/second/1-diary-2.png" alt="日記2" />
            </div>
          )}
          {index === 2 && (
            <div className="diary">
              <img src="/story/second/1-diary-3.png" alt="日記3" />
            </div>
          )}
          <div>
            <input
              type="text"
              value={userAnswers[index]}
              onChange={(e) => {
                const newAnswers = [...userAnswers];
                newAnswers[index] = e.target.value;
                setUserAnswers(newAnswers);
              }}
              placeholder="ひらがなで入力"
              disabled={correctAnswers[index] || second1State === 10}
              className="sec1-answer-input"
            />
            <button
              onClick={() => handleAnswerSubmit(index, userAnswers[index])}
              disabled={correctAnswers[index] || second1State === 10}
              style={{
                backgroundColor: correctAnswers[index] ? "grey" : "lightblue",
              }}
              className="sec1-submit-button"
            >
              送信
            </button>
          </div>
          <br></br>
        </div>
      ))}
      {second1State !== 10 && <p>{wrongMessage}</p>}
      {second1State === 10 && story4()}
      <button onClick={toSecond} className="sec1-submit-button">
        2ndに戻る
      </button>
      <br></br>
      {/* <button onClick={toHint} className='button'>ヒント</button> */}
      <br></br>
    </div>
  );
};

export default Second1;
