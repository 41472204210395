// src/components/StoryFirstPage.tsx
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./StoryFirstPage.css";
import { StoryStatusDict } from "../utils/DictionaryTypes";

const clearedText = () => {
  return (
    <div>
      <p>謎を解いたあなたは、その場にある楽譜を眺めていた。</p>
      <p>すると、どこからか声がする。</p>
      <p>「き、きみは...誰？」</p>
      <p>
        怯えた様子でこちらを見てくる。しかし、あなたの持っていた紙を見るとすぐに、
      </p>
      <p>「もしかして、君がこの謎を解いてくれたの？」</p>
      <p>と目を輝かせながら言ってきた。</p>
      <p>
        あなたは動揺しながらも、「そうだよ。この謎を解いてたんだ」と答える。
      </p>
      <br></br>
      <p>
        「私は妖精。君が謎を解いてくれたおかげで、この場所に戻ってこれたんだ。
      </p>
      <p>
        だけど、この場所がどんなところかわからないし、大事なことも思い出せなくて...」
      </p>
      <br></br>
      <p>「そういえば」</p>
      <p>妖精は何かを思い出したのか、手元から一通の手紙を取り出す。</p>
      <p>妖精の手紙を確認すると、そこには短い文章が書かれていた。</p>
      <br></br>
      <img src="/story/first/letter.png" alt="letter" className="letter" />
      <br></br>
      <p>妖精は何かを探しているようだった。</p>
      <p>そして、悩んだ様子で、</p>
      <p>「ねえ、あなたもこの場所に来たばかりでしょう？</p>
      <p>もしよかったら一緒に探索しない？</p>
      <p>
        私もどんな場所か知らないし、もしかしたら回っているうちに何か思い出すかもしれないんだ」
      </p>
      <p>あなたと妖精は、記憶を取り戻すために探索を始めるのだった。</p>
      <br></br>
    </div>
  );
};

const StoryFirstPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const storyStatus: StoryStatusDict = location.state?.storyStatus || {
    currentStatus: 1,
    maxStatus: 1,
    map: -1,
    second1Status: 0,
    second2Status: 0,
    second3Status: 0,
    second4Status: 0,
    second5Status: 0,
    second6Status: 0,
    second7Status: 0,
    second8Status: 0,
    second9Status: 0,
    second10Status: 0,
    second11Status: 0,
    second12Status: 0,
    secondId: 0,
    thirdStatus: 0,
  };

  const [inputValue, setInputValue] = useState<string>("");
  const [clearFlag, setClearFlag] = useState(
    storyStatus["maxStatus"] > 1 ? true : false
  );
  const [isLocked1, setIsLocked1] = useState<boolean>(false);
  const [isLocked2, setIsLocked2] = useState<boolean>(false);
  const [isLocked3, setIsLocked3] = useState<boolean>(false);
  const [isLocked4, setIsLocked4] = useState<boolean>(false);
  const [answer1, setAnswer1] = useState<string>("");
  const [answer2, setAnswer2] = useState<string>("");
  const [answer3, setAnswer3] = useState<string>("");
  const [answer4, setAnswer4] = useState<string>("");
  const [wrongMessage, setWrongMessage] = useState<string>("");

  const navigationToMap = (storyStatus: StoryStatusDict) => {
    if (storyStatus["map"] !== -1) {
      navigate("/map", { state: { storyStatus } });
    } else {
      navigate("/map/tmp", { state: { storyStatus } });
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const checkAnswer = () => {
    if (inputValue === "こうおん" || inputValue === "コウオン") {
      if (isLocked1) {
        setWrongMessage("その答えは既に送られているようです");
        return;
      } else {
        setWrongMessage("正解です");
        setIsLocked1(true);
        setInputValue("");
        setAnswer1(inputValue);
      }
    } else if (inputValue === "たいこ" || inputValue === "タイコ") {
      if (isLocked2) {
        setWrongMessage("その答えは既に送られているようです");
        return;
      } else {
        setWrongMessage("正解です");
        setIsLocked2(true);
        setInputValue("");
        setAnswer2(inputValue);
      }
    } else if (inputValue === "すてーじ" || inputValue === "ステージ") {
      if (isLocked3) {
        setWrongMessage("その答えは既に送られているようです");
        return;
      } else {
        setWrongMessage("正解です");
        setIsLocked3(true);
        setInputValue("");
        setAnswer3(inputValue);
      }
    } else if (inputValue === "てんぽ" || inputValue === "テンポ") {
      if (isLocked4) {
        setWrongMessage("その答えは既に送られているようです");
        return;
      } else {
        setWrongMessage("正解です");
        setIsLocked4(true);
        setInputValue("");
        setAnswer4(inputValue);
      }
    } else {
      setWrongMessage("答えが正しくないようです");
    }
  };

  useEffect(() => {
    if (isLocked1 && isLocked2 && isLocked3 && isLocked4) {
      setClearFlag(true);
    }
  }, [isLocked1, isLocked2, isLocked3, isLocked4]);

  const toSecond = () => {
    if (!clearFlag) {
      setWrongMessage("まだ全ての謎に答えていない！");
    } else {
      storyStatus["currentStatus"] = 2;
      if (storyStatus["maxStatus"] === 1) {
        storyStatus["maxStatus"] = 2;
      }
      navigate("/story/second", { state: { storyStatus } });
    }
  };

  const toOpening = () => {
    storyStatus["currentStatus"] = 0;
    navigate("/", { state: { storyStatus } });
  };

  const toHint = () => {
    window.open(
      "https://anothervision.tokyo/special/rechord-web/rechord-hint.html",
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <div className="first-page">
      <br></br>
      <button onClick={() => navigationToMap(storyStatus)} className="button">
        地図
      </button>
      <h2 className="text">1st Step</h2>
      <h3 className="text">room1に掲示された謎を解き、</h3>
      <h3 className="text">答えをすべて入力せよ</h3>
      <br></br>
      <p>あなたの解答：</p>
      <p>{answer1}</p>
      <p>{answer2}</p>
      <p>{answer3}</p>
      <p>{answer4}</p>
      <br></br>
      <input
        type="text"
        value={inputValue}
        placeholder="ひらがなで入力"
        onChange={handleInputChange}
        disabled={clearFlag}
        className="answer-input"
      />
      <button
        onClick={checkAnswer}
        disabled={clearFlag}
        className="submit-button"
      >
        送信
      </button>
      {!clearFlag && <p>{wrongMessage}</p>}
      {clearFlag && clearedText()}
      <br></br>
      {clearFlag && <h3 className="text">全ての謎を解いた！</h3>}
      <button onClick={toSecond} className="button">
        次に進む
      </button>
      <br></br>
      <button onClick={toOpening} className="button">
        最初に戻る
      </button>
      <br></br>
      {/* <button onClick={toHint} className='button'>ヒント</button> */}
      <br></br>
    </div>
  );
};

export default StoryFirstPage;
