// src/components/Hint/HintThirdPage.tsx
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./ClearPage.css";
import { StoryStatusDict } from "../utils/DictionaryTypes";

const ClearPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const storyStatus: StoryStatusDict = location.state?.storyStatus || {
    currentStatus: 4,
    maxStatus: 4,
    map: 0,
    second1Status: 0,
    second2Status: 0,
    second3Status: 0,
    second4Status: 0,
    second5Status: 0,
    second6Status: 0,
    second7Status: 0,
    second8Status: 0,
    second9Status: 0,
    second10Status: 0,
    second11Status: 0,
    second12Status: 0,
    secondId: 0,
    thirdStatus: 1,
  };

  const toThird = (storyStatus: StoryStatusDict) => {
    storyStatus["currentStatus"] = 3;
    navigate("/story/third", { state: { storyStatus } });
  };

  const toSecond = (storyStatus: StoryStatusDict) => {
    storyStatus["currentStatus"] = 2;
    navigate("/story/second", { state: { storyStatus } });
  };

  return (
    <div className="clear-page">
      <br></br>
      <button onClick={() => toThird(storyStatus)} className="button">
        戻る
      </button>
      {/* <p>{storyStatus['thirdStatus']}</p> */}
      <br></br>
      <img src="/story/Rechord-clear.png" alt="Clear" className="clear-img" />
      <br></br>
      <br></br>
      <a href="https://forms.gle/1LtWTHpir3F6aMtNA">アンケートはこちらから</a>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <div style={{ textAlign: "center" }}>
        <a
          href="https://twitter.com/intent/tweet?text=最後まで物語を見届けた！%0A%0A%23アナビレコリコ%0A%23アナビレコード%0A%23AnotherVision"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none" }}
        >
          <button
            style={{
              color: "white",
              backgroundColor: "black",
              border: "none",
              width: 250,
              padding: "0.5rem 2rem",
              borderRadius: "12px",
              textAlign: "center",
              fontWeight: "bold",
              textDecoration: "none",
              fontSize: "1.25rem",
              boxShadow: "2px 2px 8px #999",
            }}
          >
            Xでポストする
          </button>
        </a>
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <p>まだ謎が解き足りない方はこちらへ</p>
      <p>↓</p>
      <button onClick={() => toSecond(storyStatus)} className="button">
        2ndへ
      </button>
    </div>
  );
};

export default ClearPage;
