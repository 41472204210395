// src/Story/Second6.tsx
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./Second6.css";
import { StoryStatusDict } from "../../utils/DictionaryTypes";
import Cookies from "js-cookie";

// sleep関数
const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const sleepFunc = async () => {
  await sleep(3000);
};

// 0:黒, 1:白, 2:青, 3:緑, 4:赤, 5:イエロー, 6:マゼンタ, 7:シアン
type Color = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;

// 色とCSSの色名の対応
const colorMap: Record<Color, string> = {
  0: "black",
  1: "white",
  2: "blue",
  3: "green",
  4: "red",
  5: "yellow",
  6: "magenta",
  7: "cyan",
};

const changeColor = (
  color: Color,
  setColor: React.Dispatch<React.SetStateAction<Color>>
) => {
  const nextColor: Color =
    color === 1 ? 2 : color === 2 ? 3 : color === 3 ? 4 : 2;
  setColor(nextColor);
};

const Dot: React.FC<{
  id: string;
  color: Color;
  setColor: React.Dispatch<React.SetStateAction<Color>>;
  flag: boolean;
}> = ({ id, color, setColor, flag }) => {
  return (
    <div
      className="dot"
      id={`dot${id}`}
      onClick={() => {
        if (!flag) return;
        changeColor(color, setColor);
      }}
      style={{ backgroundColor: colorMap[color] }}
    ></div>
  );
};

const DotMiddle: React.FC<{ id: string; color: Color }> = ({ id, color }) => {
  return (
    <div
      className="dot"
      id={`dot${id}`}
      style={{ backgroundColor: colorMap[color] }}
    ></div>
  );
};

function synthesizeColor(colorList: Color[], backgroundColor: 0 | 1): Color {
  // 色の存在をチェックするためのセット
  const colorSet = new Set(colorList);

  // 背景色が黒の場合の光の合成
  if (backgroundColor === 0) {
    // 白があれば、それ以上の合成は不要
    if (colorSet.has(1)) return 1; // 白

    // 赤、青、緑の組み合わせまたはそれらを含む合成色のチェック
    const hasRed = colorSet.has(4) || colorSet.has(5) || colorSet.has(6);
    const hasGreen = colorSet.has(3) || colorSet.has(5) || colorSet.has(7);
    const hasBlue = colorSet.has(2) || colorSet.has(6) || colorSet.has(7);

    if (hasRed && hasGreen && hasBlue) return 1; // 白

    // 色の合成により生成される色を返す
    if (hasRed && hasBlue) return 6; // マゼンタ
    if (hasRed && hasGreen) return 5; // イエロー
    if (hasBlue && hasGreen) return 7; // シアン

    // 単一色の場合、その色を返す
    if (hasRed) return 4;
    if (hasGreen) return 3;
    if (hasBlue) return 2;
  } else if (backgroundColor === 1) {
    // 背景色が白の場合の色の合成
    if (colorSet.has(0)) return 0; // 黒があれば黒

    // イエロー、マゼンタ、シアンの組み合わせのチェック
    const hasYellow = colorSet.has(5) || colorSet.has(4) || colorSet.has(3);
    const hasMagenta = colorSet.has(6) || colorSet.has(4) || colorSet.has(2);
    const hasCyan = colorSet.has(7) || colorSet.has(3) || colorSet.has(2);

    if (hasYellow && hasMagenta && hasCyan) return 0; // 黒

    // 色の合成により生成される色を返す
    if (hasMagenta && hasCyan) return 2; // 青
    if (hasYellow && hasCyan) return 3; // 緑
    if (hasYellow && hasMagenta) return 4; // 赤

    if (hasYellow) return 5; // イエロー
    if (hasMagenta) return 6; // マゼンタ
    if (hasCyan) return 7; // シアン
  }

  // 上記の条件に一致しない場合、背景色を返す
  return backgroundColor === 0 ? 0 : 1;
}

const clearedStory = () => {
  return (
    <div className="text-center">
      <p>そこでは、ただ鉛筆の音だけが響いていた。</p>
      <p>自分の部屋で私は黙々と譜面を書き続けている。</p>
      <br></br>
      <p>昨日、私は余命宣告を受けた。長くてあと一年程度らしい。</p>
      <p>改めて振り返ると、音楽に彩られた人生だった。</p>
      <br></br>
      <p>師と出会い、音楽の楽しさを知り、多くのことを体験した。</p>
      <p>挫折を経て、新たな道を見つけ、自分の原点へ立ち帰った。</p>
      <p>広い世界を渡り歩き、必死に自分の限界に挑み続けた。</p>
      <p>そして、師との別れを乗り越え、一人前の作曲家になった。</p>
      <br></br>
      <p>決して順調な人生ではなかったが、ここまで歩んできた道に悔いはない。</p>
      <p>ただ、自分の人生の幕引きは美しいものでありたい。</p>
      <p>残りの時間をかけて私がすることは決まっていた。</p>
      <br></br>
      <p>
        曲を作る。私の人生を象徴するような、最後にして最高の作品になるような、そんな曲を。
      </p>
      <p>そう心に決めた時には、もう手が動き出していた。</p>
      <br></br>
      <p>朝も夜も、ひたすら曲を書き続ける。何度も書いては消す。</p>
      <p>残された時間の短さを理由に妥協するつもりもない。</p>
      <br></br>
      <p>不思議と苦しさは感じなかった。</p>
      <p>
        着実に完成に近づいているという感触があったし、何よりこんなに自由に曲を作れるのが嬉しくて仕方なかった。
      </p>
      <br></br>
      <br></br>
      <br></br>
      <p>一日が過ぎた。</p>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <p>一週間が過ぎた。</p>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <p>一ヶ月が過ぎた。</p>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <p>そして＿＿＿＿＿</p>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <p>私は鉛筆を置き、完成した譜面を見返す。</p>
      <p>気づけば、一年が過ぎていた。</p>
      <br></br>
      <p>この曲こそが、私にとっての「音楽」だ。</p>
      <p>この曲こそが、私の人生だ。</p>
      <br></br>
    </div>
  );
};

const Second6: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const storyStatus: StoryStatusDict = location.state?.storyStatus || {
    currentStatus: 2,
    maxStatus: 2,
    second1Status: 0,
    second2Status: 0,
    second3Status: 0,
    second4Status: 0,
    second5Status: 0,
    second6Status: 0,
    second7Status: 0,
    second8Status: 0,
    second9Status: 0,
    second10Status: 0,
    second11Status: 0,
    second12Status: 0,
    secondId: 0,
  };

  const [nowCount, setNowCount] = useState<number>(
    storyStatus["second6Status"] === 10 ? 8 : 0
  );
  const [nowStatus, setNowStatus] = useState<number[]>(
    storyStatus["second6Status"] === 10 ? [0, 1, 2, 3, 4, 5, 6, 7] : []
  );
  const [startFlag, setStartFlag] = useState<boolean>(false);
  const [notWhiteFlag, setNotWhiteFlag] = useState<boolean>(false);
  const [judgeFlag, setJudgeFlag] = useState<boolean>(false);

  const [phase0Flag, setPhase0Flag] = useState<boolean>(true);
  const [phase1Flag, setPhase1Flag] = useState<boolean>(false);
  const [clearFlag, setClearFlag] = useState<boolean>(
    storyStatus["second6Status"] === 10 ? true : false
  );

  const [dotA1Color, setDotA1Color] = useState<Color>(1);
  const [dotA2Color, setDotA2Color] = useState<Color>(1);
  const [dotA3Color, setDotA3Color] = useState<Color>(1);
  const [dotA4Color, setDotA4Color] = useState<Color>(1);
  const [dotA5Color, setDotA5Color] = useState<Color>(1);
  const [dotA6Color, setDotA6Color] = useState<Color>(1);
  const [dotA7Color, setDotA7Color] = useState<Color>(1);
  const [dotA8Color, setDotA8Color] = useState<Color>(1);

  // for debug
  const [dotB1Color, setDotB1Color] = useState<Color>(1);
  const [dotB2Color, setDotB2Color] = useState<Color>(1);
  const [dotB3Color, setDotB3Color] = useState<Color>(1);
  const [dotB4Color, setDotB4Color] = useState<Color>(1);
  const [dotB5Color, setDotB5Color] = useState<Color>(1);
  const [dotB6Color, setDotB6Color] = useState<Color>(1);

  const [dotC1Color, setDotC1Color] = useState<Color>(0);
  const [dotC2Color, setDotC2Color] = useState<Color>(0);
  const [dotC3Color, setDotC3Color] = useState<Color>(0);
  const [dotC4Color, setDotC4Color] = useState<Color>(0);
  const [dotC5Color, setDotC5Color] = useState<Color>(0);
  const [dotC6Color, setDotC6Color] = useState<Color>(0);
  const [dotC7Color, setDotC7Color] = useState<Color>(0);
  const [dotC8Color, setDotC8Color] = useState<Color>(0);
  const [dotC9Color, setDotC9Color] = useState<Color>(0);

  // for debug
  const [dotD1Color, setDotD1Color] = useState<Color>(1);
  const [dotD2Color, setDotD2Color] = useState<Color>(1);
  const [dotD3Color, setDotD3Color] = useState<Color>(1);
  const [dotD4Color, setDotD4Color] = useState<Color>(1);
  const [dotD5Color, setDotD5Color] = useState<Color>(1);
  const [dotD6Color, setDotD6Color] = useState<Color>(1);

  const [dotE1Color, setDotE1Color] = useState<Color>(1);

  useEffect(() => {
    if (
      dotA1Color !== 1 &&
      dotA2Color !== 1 &&
      dotA3Color !== 1 &&
      dotA4Color !== 1 &&
      dotA5Color !== 1 &&
      dotA6Color !== 1 &&
      dotA7Color !== 1 &&
      dotA8Color !== 1
    ) {
      setNotWhiteFlag(true);
    }
  }, [
    dotA1Color,
    dotA2Color,
    dotA3Color,
    dotA4Color,
    dotA5Color,
    dotA6Color,
    dotA7Color,
    dotA8Color,
  ]);

  useEffect(() => {
    if (
      dotA1Color !== 1 &&
      dotA2Color !== 1 &&
      dotA3Color !== 1 &&
      dotA4Color !== 1 &&
      dotA5Color !== 1 &&
      dotA6Color !== 1 &&
      dotA7Color !== 1 &&
      dotA8Color !== 1 &&
      phase0Flag &&
      startFlag
    ) {
      const dotB1Color = synthesizeColor(
        [dotA8Color, dotA1Color, dotA2Color],
        0
      );
      const dotB2Color = synthesizeColor(
        [dotA1Color, dotA2Color, dotA3Color],
        0
      );
      const dotB3Color = synthesizeColor(
        [dotA3Color, dotA4Color, dotA5Color],
        0
      );
      const dotB4Color = synthesizeColor(
        [dotA4Color, dotA5Color, dotA6Color],
        0
      );
      const dotB5Color = synthesizeColor(
        [dotA5Color, dotA6Color, dotA7Color],
        0
      );
      const dotB6Color = synthesizeColor(
        [dotA7Color, dotA8Color, dotA1Color],
        0
      );

      setDotB1Color(dotB1Color);
      setDotB2Color(dotB2Color);
      setDotB3Color(dotB3Color);
      setDotB4Color(dotB4Color);
      setDotB5Color(dotB5Color);
      setDotB6Color(dotB6Color);

      setDotC1Color(synthesizeColor([dotB1Color], 1));
      setDotC2Color(synthesizeColor([dotB1Color, dotB2Color], 1));
      setDotC3Color(synthesizeColor([dotB2Color], 1));
      setDotC4Color(synthesizeColor([dotB3Color], 1));
      setDotC5Color(synthesizeColor([dotB3Color, dotB4Color], 1));
      setDotC6Color(synthesizeColor([dotB4Color], 1));
      setDotC7Color(synthesizeColor([dotB5Color], 1));
      setDotC8Color(synthesizeColor([dotB5Color, dotB6Color], 1));
      setDotC9Color(synthesizeColor([dotB6Color], 1));

      setPhase0Flag(false);
      setPhase1Flag(true);
    }
  }, [
    dotA1Color,
    dotA2Color,
    dotA3Color,
    dotA4Color,
    dotA5Color,
    dotA6Color,
    dotA7Color,
    dotA8Color,
    phase0Flag,
    startFlag,
  ]);

  useEffect(() => {
    if (phase1Flag) {
      sleepFunc();
      const dotD1Color = synthesizeColor([dotC1Color, dotC2Color], 0);
      const dotD2Color = synthesizeColor([dotC2Color, dotC3Color], 0);
      const dotD3Color = synthesizeColor([dotC4Color, dotC5Color], 0);
      const dotD4Color = synthesizeColor([dotC5Color, dotC6Color], 0);
      const dotD5Color = synthesizeColor([dotC7Color, dotC8Color], 0);
      const dotD6Color = synthesizeColor([dotC8Color, dotC9Color], 0);

      setDotD1Color(dotD1Color);
      setDotD2Color(dotD2Color);
      setDotD3Color(dotD3Color);
      setDotD4Color(dotD4Color);
      setDotD5Color(dotD5Color);
      setDotD6Color(dotD6Color);

      setDotE1Color(
        synthesizeColor(
          [
            dotD1Color,
            dotD2Color,
            dotD3Color,
            dotD4Color,
            dotD5Color,
            dotD6Color,
          ],
          1
        )
      );
      setJudgeFlag(true);
      // setPhase2Flag(true);
      // setPhase1Flag(false);
    }
  }, [
    phase1Flag,
    dotC1Color,
    dotC2Color,
    dotC3Color,
    dotC4Color,
    dotC5Color,
    dotC6Color,
    dotC7Color,
    dotC8Color,
    dotC9Color,
  ]);

  useEffect(() => {
    if (judgeFlag && !nowStatus.includes(dotE1Color)) {
      const updatedStatus = [...nowStatus, dotE1Color];
      setNowStatus(updatedStatus);
      setNowCount((prev) => prev + 1);
      setJudgeFlag(false);
    }
  }, [nowStatus, dotE1Color, judgeFlag]);

  useEffect(() => {
    if (nowCount === 8) {
      setClearFlag(true);
      storyStatus["second6Status"] = 10;
      Cookies.set("clear_rechord_second_6", "true", { expires: 36500 });
    }
  }, [nowCount]);

  // useEffect(() => {
  //     if (phase2Flag) {
  //         sleepFunc();
  //         sleepFunc();
  //         setDotA1Color(1);
  //         setDotA2Color(1);
  //         setDotA3Color(1);
  //         setDotA4Color(1);
  //         setDotA5Color(1);
  //         setDotA6Color(1);
  //         setDotA7Color(1);
  //         setDotA8Color(1);
  //         setPhase0Flag(true);
  //         setPhase2Flag(false);
  //     }
  // }, [phase2Flag]);

  const startSynthesize = () => {
    setStartFlag(true);
    setNotWhiteFlag(false);
  };

  const resetStatus = () => {
    setDotA1Color(1);
    setDotA2Color(1);
    setDotA3Color(1);
    setDotA4Color(1);
    setDotA5Color(1);
    setDotA6Color(1);
    setDotA7Color(1);
    setDotA8Color(1);
    setPhase0Flag(true);
    setPhase1Flag(false);
    setStartFlag(false);
    //setPhase2Flag(false);
  };

  const toSecond = () => {
    storyStatus["currentStatus"] = 2;
    navigate("/story/second", { state: { storyStatus } });
  };

  const toHint = () => {
    window.open(
      "https://anothervision.tokyo/special/rechord-web/rechord-hint.html",
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <div className="sec6-container">
      <div className="outer-rectangle">
        <div className="circle white-circle-2">
          <div className="circle black-circle">
            <div className="circle white-circle"></div>
          </div>
        </div>
        <div className="number-display">{nowCount}</div>
        <div className="dots-container">
          <Dot
            id={"A1"}
            color={dotA1Color}
            setColor={setDotA1Color}
            flag={phase0Flag}
          />
          <Dot
            id={"A2"}
            color={dotA2Color}
            setColor={setDotA2Color}
            flag={phase0Flag}
          />
          <Dot
            id={"A3"}
            color={dotA3Color}
            setColor={setDotA3Color}
            flag={phase0Flag}
          />
          <Dot
            id={"A4"}
            color={dotA4Color}
            setColor={setDotA4Color}
            flag={phase0Flag}
          />
          <Dot
            id={"A5"}
            color={dotA5Color}
            setColor={setDotA5Color}
            flag={phase0Flag}
          />
          <Dot
            id={"A6"}
            color={dotA6Color}
            setColor={setDotA6Color}
            flag={phase0Flag}
          />
          <Dot
            id={"A7"}
            color={dotA7Color}
            setColor={setDotA7Color}
            flag={phase0Flag}
          />
          <Dot
            id={"A8"}
            color={dotA8Color}
            setColor={setDotA8Color}
            flag={phase0Flag}
          />
        </div>

        {phase1Flag && (
          <div className="dots-container">
            {/* <DotMiddle id={'B1'} color={dotB1Color} />
                        <DotMiddle id={'B2'} color={dotB2Color} />
                        <DotMiddle id={'B3'} color={dotB3Color} />
                        <DotMiddle id={'B4'} color={dotB4Color} />
                        <DotMiddle id={'B5'} color={dotB5Color} />
                        <DotMiddle id={'B6'} color={dotB6Color} />
                        <DotMiddle id={'D1'} color={dotD1Color} />
                        <DotMiddle id={'D2'} color={dotD2Color} />
                        <DotMiddle id={'D3'} color={dotD3Color} />
                        <DotMiddle id={'D4'} color={dotD4Color} />
                        <DotMiddle id={'D5'} color={dotD5Color} />
                        <DotMiddle id={'D6'} color={dotD6Color} /> */}

            <DotMiddle id={"C1"} color={dotC1Color} />
            <DotMiddle id={"C2"} color={dotC2Color} />
            <DotMiddle id={"C3"} color={dotC3Color} />
            <DotMiddle id={"C4"} color={dotC4Color} />
            <DotMiddle id={"C5"} color={dotC5Color} />
            <DotMiddle id={"C6"} color={dotC6Color} />
            <DotMiddle id={"C7"} color={dotC7Color} />
            <DotMiddle id={"C8"} color={dotC8Color} />
            <DotMiddle id={"C9"} color={dotC9Color} />
            <DotMiddle id={"E1"} color={dotE1Color} />
          </div>
        )}

        <div className="dots-container">
          <div className="line" id="lineAB11"></div>
          <div className="line" id="lineAB12"></div>
          <div className="line" id="lineAB16"></div>
          <div className="line" id="lineAB21"></div>
          <div className="line" id="lineAB22"></div>
          <div className="line" id="lineAB32"></div>
          <div className="line" id="lineAB33"></div>
          <div className="line" id="lineAB43"></div>
          <div className="line" id="lineAB44"></div>
          <div className="line" id="lineAB53"></div>
          <div className="line" id="lineAB54"></div>
          <div className="line" id="lineAB55"></div>
          <div className="line" id="lineAB64"></div>
          <div className="line" id="lineAB65"></div>
          <div className="line" id="lineAB75"></div>
          <div className="line" id="lineAB76"></div>
          <div className="line" id="lineAB86"></div>
          <div className="line" id="lineAB81"></div>
          <div className="line" id="lineBC11"></div>
          <div className="line" id="lineBC12"></div>
          <div className="line" id="lineBC22"></div>
          <div className="line" id="lineBC23"></div>
          <div className="line" id="lineBC34"></div>
          <div className="line" id="lineBC35"></div>
          <div className="line" id="lineBC45"></div>
          <div className="line" id="lineBC46"></div>
          <div className="line" id="lineBC57"></div>
          <div className="line" id="lineBC58"></div>
          <div className="line" id="lineBC68"></div>
          <div className="line" id="lineBC69"></div>
          <div className="line" id="lineCD11"></div>
          <div className="line" id="lineCD21"></div>
          <div className="line" id="lineCD22"></div>
          <div className="line" id="lineCD32"></div>
          <div className="line" id="lineCD43"></div>
          <div className="line" id="lineCD53"></div>
          <div className="line" id="lineCD54"></div>
          <div className="line" id="lineCD64"></div>
          <div className="line" id="lineCD75"></div>
          <div className="line" id="lineCD85"></div>
          <div className="line" id="lineCD86"></div>
          <div className="line" id="lineCD96"></div>
          <div className="line" id="lineDE11"></div>
          <div className="line" id="lineDE21"></div>
          <div className="line" id="lineDE31"></div>
          <div className="line" id="lineDE41"></div>
          <div className="line" id="lineDE51"></div>
          <div className="line" id="lineDE61"></div>
        </div>
      </div>
      <button
        onClick={startSynthesize}
        disabled={!notWhiteFlag}
        className="button"
      >
        スタート
      </button>
      <button onClick={resetStatus} disabled={!phase1Flag} className="button">
        リセット
      </button>
      {clearFlag && clearedStory()}
      <button onClick={toSecond} className="button">
        2ndに戻る
      </button>
      <br></br>
      {/* <button onClick={toHint} className='button'>ヒント</button> */}
      <br></br>
    </div>
  );
};

export default Second6;
