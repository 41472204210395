// src/Story/Second12.tsx
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./Second12.css";
import { StoryStatusDict } from "../../utils/DictionaryTypes";
import Cookies from "js-cookie";

const clearedStory = () => {
  return (
    <div>
      <p>重い扉を開けると、軋んだ音がした。</p>
      <br></br>
      <p>ここは、過去に作った曲をレコードにして保管してある部屋だ。</p>
      <p>山積みになったレコードには、うっすらほこりが積もっている。</p>
      <br></br>
      <p>師匠のように、心に響く音楽を作り出すにはどうすればいいのか。</p>
      <p>私は長い間、悩み続けてきた。</p>
      <br></br>
      <p>ここにあるどの曲も、師匠の真似をしているに過ぎない。</p>
      <p>
        過去の模倣を繰り返しながら音楽と向き合ううち、私はようやくあることに気がついた。
      </p>
      <br></br>
      <p>一瞬の景色を描く音楽は、いつも違った姿を見せる。</p>
      <p>
        だからこそ、過去の作品に「今」という新たな色を加えていけば、更新された曲になるはずだ。
      </p>
      <p>
        師匠が様々なことを私に経験させてくれたのも、そのことを教えるためだったのかもしれない。
      </p>
      <br></br>
      <p>私は繰り返す。</p>
      <p>過去の記録を更新して、新しい音楽を書く。</p>
      <br></br>
      <p>私は繰り返す。</p>
      <p>師匠の教えを大切にしながら、私にしか作れない音楽を作り出す。</p>
      <br></br>
      <p>私は繰り返す。</p>
      <p>この命が尽きない限り。</p>
      <br></br>
      <p>私の心に灯った火が、輝きを増していくのを感じた。</p>
      <br></br>
    </div>
  );
};

const second12DataList = [
  { type: "", imgSrc: "/story/second/12-r1.png", answer: "はさみ" },
  { type: "", imgSrc: "/story/second/12-r2.png", answer: "いし" },
  { type: "", imgSrc: "/story/second/12-r3.png", answer: "かみ" },
  { type: "", imgSrc: "/story/second/12-r4.png", answer: "じゃんけん" },
  { type: "", imgSrc: "/story/second/12-b1.png", answer: "りかい" },
  { type: "", imgSrc: "/story/second/12-b2.png", answer: "とり" },
  { type: "", imgSrc: "/story/second/12-b3.png", answer: "いと" },
  { type: "", imgSrc: "/story/second/12-b4.png", answer: "しりとり" },
  { type: "", imgSrc: "/story/second/12-l1.png", answer: "じゅんかん" },
  { type: "", imgSrc: "/story/second/12-l2.png", answer: "さいせい" },
  { type: "", imgSrc: "/story/second/12-l3.png", answer: "かんじゅせい" },
  { type: "", imgSrc: "/story/second/12-l4.png", answer: "さいん" },
];

const Second12: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const storyStatus: StoryStatusDict = location.state?.storyStatus || {
    currentStatus: 2,
    maxStatus: 2,
    second1Status: 0,
    second2Status: 0,
    second3Status: 0,
    second4Status: 0,
    second5Status: 0,
    second6Status: 0,
    second7Status: 0,
    second8Status: 0,
    second9Status: 0,
    second10Status: 0,
    second11Status: 0,
    second12Status: 0,
    secondId: 0,
  };

  const [wrongMessage, setWrongMessage] = useState<string>("");
  const [userAnswer, setUserAnswer] = useState("");
  const [second12State, setSecond12State] = useState(
    storyStatus["second12Status"] === 10 ? 12 : 0
  );

  useEffect(() => {
    const state = Number(Cookies.get("rechord_second_12_state")) || 0;
    setSecond12State(state === 10 ? 12 : state);
  }, []);

  const handleAnswerSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (userAnswer === second12DataList[second12State]["answer"]) {
      if (second12State === 12) {
        storyStatus["second12Status"] = 10;
        Cookies.set("clear_rechord_second_12", "true", { expires: 36500 });
      }
      setWrongMessage("");
      setSecond12State(second12State + 1);
      if (second12State + 1 != 10) {
        Cookies.set("rechord_second_12_state", `${second12State + 1}`, {
          expires: 36500,
        });
      }

      setUserAnswer("");
    } else {
      setWrongMessage("答えが正しくないようだ");
    }
  };

  const toSecond = () => {
    storyStatus["currentStatus"] = 2;
    if (second12State === 12) {
      storyStatus["second12Status"] = 10;
      Cookies.set("clear_rechord_second_12", "true", { expires: 36500 });
    }
    navigate("/story/second", { state: { storyStatus } });
  };

  const toHint = () => {
    window.open(
      "https://anothervision.tokyo/special/rechord-web/rechord-hint.html",
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <div className="sec12-container">
      <br></br>
      <h2 className="text">2nd 老年期の記憶1</h2>
      <h3 className="text">壁に貼られた3つの法則を使い、</h3>
      <h3 className="text">「赤の謎」「青の謎」「最後の謎」を順に解け</h3>
      {second12DataList.slice(0, second12State + 1).map((data, index) => (
        <div key={index} style={{ marginTop: "2rem" }}>
          <div className="sec12-text">{data.type}</div>
          {index < second12State && (
            <div style={{ fontSize: "1.25rem", fontWeight: "bold" }}>
              答え: {data.answer}
            </div>
          )}
          <img src={data.imgSrc} alt={`謎 ${index + 1}`} className="sec12-Q" />
        </div>
      ))}
      {/* {second12State === 12 ? (
                second12DataList.map((data, index) => (
                    <div key={index}>
                        <div className="sec12-text">{data.type}</div>
                        <img src={data.imgSrc} alt={`謎 ${index + 1}`} className="sec12-Q" />
                    </div>
                ))
            ) : (
                <div>
                    <div className="sec12-text">{second12DataList[second12State].type}</div>
                    <img src={second12DataList[second12State].imgSrc} alt={`謎 ${second12State + 1}`} className="sec12-Q" />
                </div>
            )} */}
      <form onSubmit={handleAnswerSubmit} className="sec12-form">
        <input
          type="text"
          value={userAnswer}
          onChange={(e) => setUserAnswer(e.target.value)}
          className="sec12-answer-input"
          placeholder="ひらがなで入力"
          disabled={
            second12State === 12 || storyStatus["second12Status"] === 10
          }
        />
        <button
          type="submit"
          className="sec12-submit-button"
          disabled={
            second12State === 12 || storyStatus["second12Status"] === 10
          }
        >
          送信
        </button>
      </form>
      <br></br>
      {second12State !== 12 && <p>{wrongMessage}</p>}
      {second12State === 12 && clearedStory()}
      <button onClick={toSecond} className="button">
        2ndに戻る
      </button>
      <br></br>
      {/* <button onClick={toHint} className='button'>ヒント</button> */}
      <br></br>
    </div>
  );
};

export default Second12;
