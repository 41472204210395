// src/components/MapPage.tsx
import React, { useState, useEffect, useRef, ReactNode } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './MapTmpPage.css';
import { StoryStatusDict } from '../utils/DictionaryTypes';

const MapTmpPage: React.FC = () => {
    // ステータスの取得
    const navigate = useNavigate();
    const location = useLocation();
    const storyStatus: StoryStatusDict = location.state?.storyStatus || {
        currentStatus: 1,
        maxStatus: 1,
        map: -1,
        second1Status: 0,
        second2Status: 0,
        second3Status: 0,
        second4Status: 0,
        second5Status: 0,
        second6Status: 0,
        second7Status: 0,
        second8Status: 0,
        second9Status: 0,
        second10Status: 0,
        second11Status: 0,
        second12Status: 0,
        secondId: 0,
        thirdStatus: 0,
    };

    const goToMap = (storyStatus: StoryStatusDict) => {
        storyStatus['map'] = 0;
        navigate('/map', { state: { storyStatus } });
    }

    return (
        <div className='map-tmp'>
            <br></br>
            <h2 className='text'>地図の見方</h2>
            <img src='/story/mapexample.png' alt='example' className='example'/>
            <br></br>
            <br></br>
            <button onClick={() => goToMap(storyStatus)} className='button'>地図を表示</button>
        </div>
    );
}

export default MapTmpPage;