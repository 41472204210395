// src/Story/Second9.tsx
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./Second9.css";
import { StoryStatusDict } from "../../utils/DictionaryTypes";
import Cookies from "js-cookie";

const story1 = () => {
  return (
    <div>
      <p>
        わいわいと賑やかな声が聞こえる。私はお祝いのパーティが開かれる会場にいた。
      </p>
      <p>テーブルには様々な料理が並べられている。</p>
      <p>壁には、きらびやかな装飾が施され、楽しげな空気で満たされていた。</p>
      <br></br>
      <p>
        今日のパーティーには近所に住む家族も呼ばれ、子供たちは庭ではしゃいでいる。
      </p>
      <p>親たちはそんな子供たちの様子を見て、微笑ましく思っているようだ。</p>
      <br></br>
      <p>
        会場が温かい空気に包まれていくと、ある家族の親が何やら準備をし始めた。
      </p>
      <p>「よし、これからみんなでビンゴ大会をしよう！」</p>
      <p>わーい、と子供たちが集まってくる。</p>
      <br></br>
      <p>
        「もちろん、ただのビンゴ大会じゃない。”とっておき”の景品を用意したんだ」
      </p>
      <p>そう言うと、男は箱にかけられた赤い幕をめくる。</p>
      <p>幕の下から現れたのは新品の”バイオリン”だった。</p>
      <br></br>
      <p>
        「一番多くビンゴを揃えられた人には、このバイオリンをプレゼントします！」
      </p>
      <p>どこからともなく歓声が上がる。</p>
      <br></br>
      <p>私もビンゴカードを一枚もらい、このゲームに参加するのだった。</p>
      <br></br>
      <p>—----------------</p>
      <br></br>
      <p>「そんなことがあったんだねえ」</p>
      <p>妖精は思い出した記憶を振り返って言う。</p>
      <br></br>
      <p>「ビンゴカードの謎を解けば、もっと記憶を思い出せるかもしれない」</p>
      <p>あなたは妖精と協力しながら、この謎を解き明かすことにした。</p>
      <br></br>
    </div>
  );
};

const story2 = () => {
  return (
    <div>
      <p>
        少しずつカードのマスが開いていき、あと１つでビンゴを達成できるところまで来た。
      </p>
      <p>「やった！ここが開けば景品がもらえるね」</p>
      <p>あなたが嬉しさのあまり、大きな声で</p>
      <p>「リーチ！」</p>
      <p>と叫ぶと、その声に続いて他のお客さんも同じことを言い始める。</p>
      <br></br>
      <p>「私もリーチ！」「やった！あと１つで揃うぞ！」</p>
      <br></br>
      <p>妖精はその様子を見て、焦っているようだった。</p>
      <p>
        「ま、まずい...。確かに、マスがあと１つ開けばビンゴになるけど、このまま開けたら他のお客さんもビンゴが揃っちゃう...。
      </p>
      <p>
        バイオリンは１つしかないから、どうにかして私だけが一位になる方法を考えないと...」
      </p>
      <br></br>
      <p>あなたと妖精はいくつかマスの開いたビンゴカードを眺める。</p>
      <br></br>
      <p>「もしも同時に２列のビンゴを作れたら、一位になれるかもしれないね」</p>
      <br></br>
      <p>確かにできそうなことはそれくらいだ。</p>
      <p>しかし、同時に２列のビンゴを作ることは可能なのだろうか？</p>
      <br></br>
      <p>あなたは、なんとか知恵を絞って策を考えることにした。</p>
      <br></br>
    </div>
  );
};

const story3 = () => {
  return (
    <div>
      <p>ピアノの音が鳴る。私は確かめるように一つずつ鍵盤を押す。</p>
      <br></br>
      <p>
        両親は私が幼い頃から教育熱心で、将来のためになるであろうことは何でもやらせた。
      </p>
      <p>特にピアノを習うことには力を入れていた。</p>
      <p>
        「自分の気持ちを表現するために、小さい頃から音楽によって様々な情景を想像できるようにするべきだ」という考えを持っていたようだった。
      </p>
      <br></br>
      <p>
        最初は何も演奏することができず、鍵盤の位置を覚えるのに必死だった。でも、練習を重ねていくうちに、それなりに上達していった。
      </p>
      <br></br>
      <p>しかし、その時はそこまで音楽が好きだったわけでもなかった。</p>
      <p>
        もちろん、私が「師匠」と呼んでいるピアノの先生や、両親に演奏を褒められることは嬉しいし、練習に打ち込みたい気持ちにもなる。けれど、弾くことそのものが楽しいと思っているわけではなかった。
      </p>
      <p>
        棚にある楽譜を取り出しても、ただ眺めるだけで、弾きたいとも思わなかった。
      </p>
      <br></br>
      <p>
        それでも両親が悲しむ姿を見たくない私は、どうにか自分を鼓舞してピアノの練習を続けた。
      </p>
      <p>音楽を楽しむ気持ちが揃うには、師匠の一押しが必要だったのだ。</p>
      <br></br>
    </div>
  );
};

const Second9: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const storyStatus: StoryStatusDict = location.state?.storyStatus || {
    currentStatus: 2,
    maxStatus: 2,
    second1Status: 0,
    second2Status: 0,
    second3Status: 0,
    second4Status: 0,
    second5Status: 0,
    second6Status: 0,
    second7Status: 0,
    second8Status: 0,
    second9Status: 0,
    second10Status: 0,
    second11Status: 0,
    second12Status: 0,
    secondId: 0,
  };

  const textInst = "掲示物を見て謎を解こう";
  const textInst2 = "謎を解こう";

  const [second9State, setSecond9State] = useState<number>(
    storyStatus["second9Status"]
  );
  const [phase1Count, setPhase1Count] = useState<number>(5);

  const keywordsPhase1 = ["くじ", "いんかん", "STRING", "さいき", "はな"];

  const [inputs1, setInputs1] = useState<string[]>(["", "", "", "", ""]);
  const [isLocked1, setIsLocked1] = useState<boolean[]>([
    false,
    false,
    false,
    false,
    false,
  ]);
  const [answeredKeywords1, setAnsweredKeyword1] = useState<string[]>([]);
  const [userAnswer, setUserAnswer] = useState("");
  const [wrongMessage, setWrongMessage] = useState("");

  const handleSubmit1 = (index: number, e: React.FormEvent) => {
    e.preventDefault();
    const input = inputs1[index].trim();

    if (answeredKeywords1.includes(input)) {
      setWrongMessage("その答えは既に送られているようです");
      return;
    }

    if (keywordsPhase1.includes(input)) {
      setWrongMessage("正解！あなたは対応するビンゴの穴を開けた。");
      setAnsweredKeyword1([...answeredKeywords1, input]);
      const updatedIsLocked = [...isLocked1];
      updatedIsLocked[index] = true;
      setIsLocked1(updatedIsLocked);
    } else {
      setWrongMessage("答えが正しくないようです(解答形式に注意してください)");
    }
  };

  const handleChange1 = (index: number, value: string) => {
    const updatedInputs = [...inputs1];
    updatedInputs[index] = value;
    setInputs1(updatedInputs);
  };

  const handleSubmit2 = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (userAnswer === "HIT") {
      setSecond9State(2);
      setWrongMessage("");
    } else {
      setWrongMessage("答えが正しくないようです(解答形式に注意してください)");
    }
  };

  useEffect(() => {
    if (second9State >= 1) {
      setIsLocked1([true, true, true, true, true]);
    }
  }, [second9State]);

  useEffect(() => {
    if (isLocked1.includes(false)) {
      setPhase1Count(isLocked1.filter((value) => !value).length);
    } else {
      if (second9State === 0) {
        setSecond9State(1);
        setWrongMessage("");
      }
    }
  }, [isLocked1, second9State]);

  const toSecond = () => {
    storyStatus["currentStatus"] = 2;
    if (second9State >= 2) {
      storyStatus["second9Status"] = 10;
      Cookies.set("clear_rechord_second_9", "true", { expires: 7 });
    } else if (second9State === 1) {
      storyStatus["second9Status"] = 1;
    }
    navigate("/story/second", { state: { storyStatus } });
  };

  const toHint = () => {
    window.open(
      "https://anothervision.tokyo/special/rechord-web/rechord-hint.html",
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <div className="sec9-container">
      <br></br>
      <h2 className="text">2nd 幼少期の記憶3</h2>
      {story1()}
      <img src="/story/second/9-bingo.png" alt="bingo" className="sec9-bingo" />
      {isLocked1.includes(false) && (
        <div>
          <h3 className="text">{textInst}</h3>
          <p>※日本語での解答はひらがな、</p>
          <p>アルファベットでの解答は英字大文字</p>
          <p>を使用してください</p>
          <h3 className="text">現在解答できる問題数: 残り{phase1Count}問</h3>
          {inputs1.map((input, index) => (
            <form
              key={index}
              onSubmit={(e) => handleSubmit1(index, e)}
              className="sec9-form"
            >
              <input
                type="text"
                value={input}
                onChange={(e) => handleChange1(index, e.target.value)}
                className="sec9-answer-input"
                disabled={isLocked1[index]}
              />
              <button
                type="submit"
                disabled={isLocked1[index]}
                style={{
                  backgroundColor: isLocked1[index] ? "grey" : "lightblue",
                }}
                className="sec9-submit-button"
              >
                送信
              </button>
            </form>
          ))}
        </div>
      )}
      {second9State === 0 && <p>{wrongMessage}</p>}
      <br></br>
      {second9State >= 1 && story2()}
      <br></br>
      {second9State === 1 && (
        <div>
          <h3>{textInst2}</h3>
          <p>※日本語での解答はひらがな、</p>
          <p>アルファベットでの解答は英字大文字</p>
          <p>を使用してください</p>
          <h3>現在解答できる問題数: 残り1問</h3>
        </div>
      )}
      {second9State >= 2 && <h3>現在解答できる問題数: 残り0問</h3>}
      {second9State >= 1 && (
        <form onSubmit={handleSubmit2} className="sec9-form">
          <input
            type="text"
            value={userAnswer}
            onChange={(e) => setUserAnswer(e.target.value)}
            className="sec9-answer-input"
            disabled={second9State >= 2}
          />
          <button
            type="submit"
            disabled={second9State >= 2}
            className="sec9-submit-button"
          >
            送信
          </button>
        </form>
      )}
      {second9State === 1 && <p>{wrongMessage}</p>}
      <br></br>
      {second9State >= 2 && story3()}
      <button onClick={toSecond} className="button">
        2ndに戻る
      </button>
      <br></br>
      {/* <button onClick={toHint} className='button'>ヒント</button> */}
      <br></br>
    </div>
  );
};

export default Second9;
