// src/components/Hint/HintThirdPage.tsx
import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";

const ClearCookie = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const v = params.get("second_step");
    if (v) {
      Cookies.set(`clear_rechord_second_${v}`, "true", { expires:7 });
      navigate("/story/second");
    }
  }, []);

  return <></>;
};

export default ClearCookie;
