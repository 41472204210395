// src/components/Hint/HintThirdPage.tsx
import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { StoryStatusDict } from "../../utils/DictionaryTypes";
import Cookies from "js-cookie";

const EightBirds = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const storyStatus: StoryStatusDict = location.state?.storyStatus || {
    currentStatus: 4,
    maxStatus: 4,
    map: 0,
    second1Status: 0,
    second2Status: 0,
    second3Status: 0,
    second4Status: 0,
    second5Status: 0,
    second6Status: 0,
    second7Status: 0,
    second8Status: 0,
    second9Status: 0,
    second10Status: 0,
    second11Status: 0,
    second12Status: 0,
    secondId: 0,
  };

  useEffect(() => {}, []);

  return <></>;
};

export default EightBirds;
