// src/Story/Second11.tsx
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./Second11.css";
import { StoryStatusDict } from "../../utils/DictionaryTypes";
import Cookies from "js-cookie";

const clearedStory = () => {
  return (
    <div>
      <p>激しい雨音がする。窓の外は真っ暗だった。</p>
      <br></br>
      <p>音楽を楽しむ気持ちがあれば、曲にメッセージを込めて届けられるはず。</p>
      <p>
        端から見れば無謀な挑戦だが、私はそんな決意を持って作曲コンペに応募した。
      </p>
      <br></br>
      <p>しかし、現実は厳しいものだった。</p>
      <p>結果は予選落ち。</p>
      <p>予選くらいなら、と期待していた自分の甘さが恥ずかしい。</p>
      <br></br>
      <p>コンペで競う相手は、私よりもずっと努力を積み重ねてきた人たちだ。</p>
      <p>
        そんな人々と比べれば、私の階級(ランク)はまだまだ下の下と言っていい。
      </p>
      <p>築き上げてきた自信が一気に崩れ、ひどく絶望した。</p>
      <br></br>
      <p>
        その一方で、自分に足りないものが何なのか、わかったような気もしていた。
      </p>
      <br></br>
      <p>どうすれば、メッセージを音楽に乗せることができるのか。</p>
      <p>記憶に残る曲を作るために、どんな努力が必要なのか。</p>
      <br></br>
      <p>
        コンペでの失敗は苦しいものだった。だが、新たな刺激を受けたことで、より真剣に作曲と向き合えるようになった気がする。
      </p>
      <p>いつの間にか雨は止み、光が差し始めていた。</p>
      <br></br>
      <br></br>
      <br></br>
      <p>
        大学を卒業してからも、作曲家としての腕を磨くため、様々な勉強を続けた。
      </p>
      <p>才能がなくても、努力を積み重ねることで未来を切り拓くと心に誓った。</p>
      <br></br>
      <p>しかし、一つの場所に留まっていても新しいアイデアは生まれない。</p>
      <br></br>
      <p>私は思い切って大きな一歩を踏み出すことに決めた。</p>
      <br></br>
    </div>
  );
};

const Second11: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const storyStatus: StoryStatusDict = location.state?.storyStatus || {
    currentStatus: 2,
    maxStatus: 2,
    second1Status: 0,
    second2Status: 0,
    second3Status: 0,
    second4Status: 0,
    second5Status: 0,
    second6Status: 0,
    second7Status: 0,
    second8Status: 0,
    second9Status: 0,
    second10Status: 0,
    second11Status: 0,
    second12Status: 0,
    secondId: 0,
  };

  const [introTextFlag, setIntroTextFlag] = useState(true);
  const [userAnswer, setUserAnswer] = useState<string>("");
  const [answeredList, setAnsweredList] = useState<{
    [imgName: string]: boolean;
  }>({
    q1: false,
    q2: false,
    q3: false,
    q4: false,
    "q-yellow": false,
    q5: false,
    q6: false,
    q7: false,
    "q-black": false,
    q8: false,
    q9: false,
    qblue: false,
    q10: false,
    "q-red": false,
    "q-last": false,
  });
  const [imgSrcList, setImgSrcList] = useState<{ [imgName: string]: string }>({
    q1: "/story/second/11-number-1.png",
    q2: "/story/second/11-number-2.png",
    q3: "/story/second/11-number-3.png",
    q4: "/story/second/11-number-4.png",
    "q-yellow": "/story/second/11-key-yellow.png",
    q5: "/story/second/11-number-5.png",
    q6: "/story/second/11-number-6.png",
    q7: "/story/second/11-number-7.png",
    "q-black": "/story/second/11-key-black.png",
    q8: "/story/second/11-number-8.png",
    q9: "/story/second/11-number-9.png",
    "q-blue": "/story/second/11-key-blue.png",
    q10: "/story/second/11-number-10.png",
    "q-red": "/story/second/11-key-red.png",
    "q-last": "/story/second/11-number-0.png",
  });
  const [selectedImage, setSelectedImage] = useState<string>("");
  const [nowScore, setNowScore] = useState(0);
  const [nowRank, setNowRank] = useState("D");
  const [forNextRank, setForNextRank] = useState(20);

  const toggleIntroText = () => {
    setIntroTextFlag(!introTextFlag);
  };

  const ansDict: { [imgName: string]: [string, string] } = {
    q1: ["CUT", "/story/second/11-1.png"],
    q2: ["WINE", "/story/second/11-2.png"],
    q3: ["CLOCK", "/story/second/11-3.png"],
    q4: ["DESERT", "/story/second/11-4.png"],
    "q-yellow": ["とうもろこし", "/story/second/11-last1.png"],
    q5: ["ノンストップ", "/story/second/11-5.png"],
    q6: ["かみなり", "/story/second/11-6.png"],
    q7: ["ごま", "/story/second/11-7.png"],
    "q-black": ["さんぐらす", "/story/second/11-last2.png"],
    q8: ["あえん", "/story/second/11-8.png"],
    q9: ["かめん", "/story/second/11-9.png"],
    "q-blue": ["あおおに", "/story/second/11-last3.png"],
    q10: ["みずに", "/story/second/11-10.png"],
    "q-red": ["いちみ", "/story/second/11-last4.png"],
    q0: ["解なし", "/popup/popup_dummy.png"],
    "q-last": ["れい", "/story/second/11-last5.png"],
  };

  const answerEnglishList = ["CUT", "WINE", "CLOCK", "DESERT"];
  const answerHiraganaList = [
    "とうもろこし",
    "かみなり",
    "ごま",
    "さんぐらす",
    "あえん",
    "かめん",
    "あおおに",
    "みずに",
    "いちみ",
    "れい",
  ];
  const answerKatakanaList = ["ノンストップ"];
  const tenPointAnswerList = [
    "とうもろこし",
    "さんぐらす",
    "あおおに",
    "いちみ",
    "れい",
  ];

  const handleImageClick = (image: string) => {
    setSelectedImage(image);
  };

  const handleClosePopup = () => {
    setSelectedImage("");
  };

  const handleAnswerSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (
      userAnswer === ansDict[selectedImage][0] &&
      !answeredList[selectedImage]
    ) {
      setSelectedImage("");
      const updatedAnsweredList = answeredList;
      updatedAnsweredList[selectedImage] = true;
      setAnsweredList(updatedAnsweredList);
      const updatedImgSrcList = imgSrcList;
      updatedImgSrcList[selectedImage] =
        "/unlockedImage" + imgSrcList[selectedImage];
      setImgSrcList(updatedImgSrcList);

      if (tenPointAnswerList.includes(userAnswer)) {
        setNowScore((prevScore) => prevScore + 10);
      } else {
        setNowScore((prevScore) => prevScore + 5);
      }
    } else if (answeredList[selectedImage]) {
      alert("その答えは既に送信しているようです");
    } else {
      alert("答えが正しくないようです");
    }
    setUserAnswer("");
  };

  useEffect(() => {
    if (nowScore <= 15) {
      setNowRank("D");
      setForNextRank(20 - nowScore);
    } else if (nowScore <= 55) {
      setNowRank("C");
      setForNextRank(60 - nowScore);
    } else if (nowScore <= 85) {
      setNowRank("B");
      setForNextRank(90 - nowScore);
    } else if (nowScore <= 95) {
      setNowRank("A");
      setForNextRank(100 - nowScore);
    }
    if (nowScore === 100) {
      setNowRank("S");
      setForNextRank(0);
      setSelectedImage("");
    }
  }, [nowScore]);

  const toSecond = () => {
    storyStatus["currentStatus"] = 2;
    if (nowRank === "S") {
      storyStatus["second11Status"] = 10;
      Cookies.set("clear_rechord_second_11", "true", { expires: 36500 });
    }
    navigate("/story/second", { state: { storyStatus } });
  };

  const toHint = () => {
    window.open(
      "https://anothervision.tokyo/special/rechord-web/rechord-hint.html",
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <div className="sec11-container">
      <br></br>
      <h2>2nd 壮年期の記憶2</h2>
      <br></br>
      {introTextFlag && (
        <div>
          <h3>ルール</h3>
          <img
            src="/story/second/11-rule.png"
            alt="rule"
            className="sec11-rule-image"
          />
          <br></br>
          <br></br>
        </div>
      )}
      {!introTextFlag && (
        <div>
          <h3>現在の得点・ランク</h3>
          {storyStatus["second11Status"] === 10 && (
            <h3>※あなたは既にこのステージをクリアしています</h3>
          )}
          <p>
            <b>得点</b>: <span className="sec11-bold-red-text">{nowScore}</span>{" "}
            <b>ランク</b>:{" "}
            <span className="sec11-bold-red-text">{nowRank}</span>
          </p>
          <p>次のランクまで残り{forNextRank}点</p>
          <br></br>
          <h3>謎一覧</h3>
          <div className="sec11-main-field">
            <img
              src="/story/second/11-pyramid.png"
              alt="pyramid"
              className="sec11-pyramid"
            />

            <img
              src={imgSrcList["q-last"]}
              alt="key0"
              className="key-white"
              onClick={() => handleImageClick("q-last")}
            />
            <div></div>
            <img
              src={imgSrcList["q10"]}
              alt="question10"
              className="img10"
              onClick={() => handleImageClick("q10")}
            />
            <img
              src={imgSrcList["q-red"]}
              alt="key-red"
              className="key-red"
              onClick={() => {
                if (answeredList["q10"]) {
                  handleImageClick("q-red");
                } else {
                  alert("横の問題を全て解くことで閲覧できます");
                }
              }}
            />
            <div></div>
            <img
              src={imgSrcList["q8"]}
              alt="question8"
              className="img8"
              onClick={() => handleImageClick("q8")}
            />
            <img
              src={imgSrcList["q9"]}
              alt="question9"
              className="img9"
              onClick={() => handleImageClick("q9")}
            />
            <img
              src={imgSrcList["q-blue"]}
              alt="key-blue"
              className="key-blue"
              onClick={() => {
                if (answeredList["q9"] && answeredList["q8"]) {
                  handleImageClick("q-blue");
                } else {
                  alert("横の問題を全て解くことで閲覧できます");
                }
              }}
            />
            <div></div>
            <img
              src={imgSrcList["q5"]}
              alt="question5"
              className="img5"
              onClick={() => handleImageClick("q5")}
            />
            <img
              src={imgSrcList["q6"]}
              alt="question6"
              className="img6"
              onClick={() => handleImageClick("q6")}
            />
            <img
              src={imgSrcList["q7"]}
              alt="question7"
              className="img7"
              onClick={() => handleImageClick("q7")}
            />
            <img
              src={imgSrcList["q-black"]}
              alt="key-black"
              className="key-black"
              onClick={() => {
                if (
                  answeredList["q5"] &&
                  answeredList["q6"] &&
                  answeredList["q7"]
                ) {
                  handleImageClick("q-black");
                } else {
                  alert("横の問題を全て解くことで閲覧できます");
                }
              }}
            />
            <div></div>
            <img
              src={imgSrcList["q1"]}
              alt="question1"
              className="img1"
              onClick={() => handleImageClick("q1")}
            />
            <img
              src={imgSrcList["q2"]}
              alt="question2"
              className="img2"
              onClick={() => handleImageClick("q2")}
            />
            <img
              src={imgSrcList["q3"]}
              alt="question3"
              className="img3"
              onClick={() => handleImageClick("q3")}
            />
            <img
              src={imgSrcList["q4"]}
              alt="question4"
              className="img4"
              onClick={() => handleImageClick("q4")}
            />
            <img
              src={imgSrcList["q-yellow"]}
              alt="key-yellow"
              className="key-yellow"
              onClick={() => {
                if (
                  answeredList["q1"] &&
                  answeredList["q2"] &&
                  answeredList["q3"] &&
                  answeredList["q4"]
                ) {
                  handleImageClick("q-yellow");
                } else {
                  alert("横の問題を全て解くことで閲覧できます");
                }
              }}
            />
          </div>
          {selectedImage !== "" && (
            <div className="sec11-popup-container">
              <img
                src={ansDict[selectedImage][1]}
                alt={selectedImage}
                className="sec11-popup-image"
              />
              <form onSubmit={handleAnswerSubmit}>
                {answerEnglishList.includes(ansDict[selectedImage][0]) && (
                  <input
                    type="text"
                    value={userAnswer}
                    onChange={(e) => setUserAnswer(e.target.value)}
                    placeholder="アルファベット(大文字)で入力"
                    className="sec11-popup-input"
                  />
                )}
                {answerHiraganaList.includes(ansDict[selectedImage][0]) && (
                  <input
                    type="text"
                    value={userAnswer}
                    onChange={(e) => setUserAnswer(e.target.value)}
                    placeholder="ひらがなで入力"
                    className="sec11-popup-input"
                  />
                )}
                {answerKatakanaList.includes(ansDict[selectedImage][0]) && (
                  <input
                    type="text"
                    value={userAnswer}
                    onChange={(e) => setUserAnswer(e.target.value)}
                    placeholder="カタカナで入力"
                    className="sec11-popup-input"
                  />
                )}
                {selectedImage !== "q0" && (
                  <button type="submit" className="sec11-submit-button">
                    送信
                  </button>
                )}
              </form>
              <button className="sec11-close-button" onClick={handleClosePopup}>
                ✖️
              </button>
            </div>
          )}
          <br></br>
          <br></br>
          {(storyStatus["second11Status"] === 10 || nowRank === "S") &&
            clearedStory()}
          <br></br>
        </div>
      )}

      {/* TODO: ボタンを大きくする */}

      {nowRank !== "S" && (
        <button onClick={toggleIntroText} className="button" style={{}}>
          {introTextFlag ? "ゲームに進む" : "説明に戻る"}
        </button>
      )}
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <button onClick={toSecond} className="button">
        2ndに戻る
      </button>
      <br></br>
      {/* <button onClick={toHint} className='button'>ヒント</button> */}
      <br></br>
    </div>
  );
};

export default Second11;
