// src/App.tsx
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import OpeningPage from "./components/OpeningPage";
import MapPage from "./components/MapPage";
import MapTmpPage from "./components/MapTmpPage";
import StoryFirstPage from "./components/StoryFirstPage";
import StorySecondPage from "./components/StorySecondPage";
import StorySecondTmpPage from "./components/StorySecondTmpPage";
import StoryThirdPage from "./components/StoryThirdPage";
import ClearPage from "./components/ClearPage";
import Second1 from "./components/Story/Second1";
import Second2 from "./components/Story/Second2";
import Second3 from "./components/Story/Second3";
import Second4 from "./components/Story/Second4";
import Second5 from "./components/Story/Second5";
import Second6 from "./components/Story/Second6";
import Second7 from "./components/Story/Second7";
import Second8 from "./components/Story/Second8";
import Second9 from "./components/Story/Second9";
import Second10 from "./components/Story/Second10";
import Second11 from "./components/Story/Second11";
import Second12 from "./components/Story/Second12";
import EightBirds from "./components/Cookie/EightBirds";
import ClearCookie from "./components/Cookie/ClearCookie";

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<OpeningPage />} />
          {/* <Route path="/story" element={<StoryPage />} /> */}
          {/* メインとなるページ */}
          <Route path="/map" element={<MapPage />} />
          <Route path="/map/tmp" element={<MapTmpPage />} />
          <Route path="/story/first" element={<StoryFirstPage />} />
          <Route path="/story/second" element={<StorySecondPage />} />
          <Route path="/story/second/tmp" element={<StorySecondTmpPage />} />
          <Route path="/story/third" element={<StoryThirdPage />} />
          <Route path="/clear" element={<ClearPage />} />
          {/* 中謎ページ */}
          <Route path="/story/second/1" element={<Second1 />} />
          <Route path="/story/second/2" element={<Second2 />} />
          <Route path="/story/second/3" element={<Second3 />} />
          <Route path="/story/second/4" element={<Second4 />} />
          <Route path="/story/second/5" element={<Second5 />} />
          <Route path="/story/second/6" element={<Second6 />} />
          <Route path="/story/second/7" element={<Second7 />} />
          <Route path="/story/second/8" element={<Second8 />} />
          <Route path="/story/second/9" element={<Second9 />} />
          <Route path="/story/second/10" element={<Second10 />} />
          <Route path="/story/second/11" element={<Second11 />} />
          <Route path="/story/second/12" element={<Second12 />} />

          {/* 臨時処理 */}
          <Route path="/eight_birds" element={<EightBirds />} />
          <Route path="/save_clear" element={<ClearCookie />} />
        </Routes>
        <a
          href="https://anothervision.tokyo/special/rechord-web/rechord-hint.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button
            style={{
              position: "fixed",
              bottom: 5,
              right: 5,
              fontWeight: "bold",
              width: 80,
              height: 80,
              borderRadius: 9999,
            }}
          >
            ヒントは
            <br />
            こちら
          </button>
        </a>
      </div>
    </Router>
  );
}

export default App;
