// src/Story/Second2.tsx
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./Second2.css";
import { StoryStatusDict } from "../../utils/DictionaryTypes";
import Cookies from "js-cookie";

const answerList: String[] = [
  "わし",
  "つる",
  "ふくろう",
  "きじ",
  "はと",
  "とき",
  "いんこ",
  "たか",
];
let yourAnswerList: String[] = [];

const clearedStory = () => {
  return (
    <div>
      <p>ガチャ、と鍵を開けて家の中に入る。</p>
      <p>郵便受けを見ると、二通の手紙が届いていた。</p>
      <br></br>
      <p>自室に入り、手紙の送り主を確認する。</p>
      <p>
        片方は、私の師匠からだった。師匠とは、今でも月に一度手紙を送り合っている。
      </p>
      <p>もう片方は、病院からだった。こちらは、全く身に覚えがない。</p>
      <p>私はまず、得体の知れない病院からの手紙に手を伸ばした。</p>
      <br></br>
      <p>そこに記されていたのは、師匠の訃報だった。</p>
      <br></br>
      <p>状況を呑み込むことができなかった。</p>
      <p>
        先月までは、病気のことなんて何も書かれていなかった。それどころか、近所の子供たちとの、楽しげな演奏会の写真も同封されていたのに。
      </p>
      <br></br>
      <p>
        急いでもう一通の手紙を開く。小さく、か細い文字で、音楽や彼女の人生についてびっしりと書かれていた。自分の死期を悟って、ひたすら書き連ねたようだった。
      </p>
      <p>最後の一枚は、白紙だった。最期まで、私に手紙を書いていたらしい。</p>
      <br></br>
      <p>
        私は師匠の想いのこもった手紙を書きかけの譜面の上に乗せ、しばらくそれを眺めていた。
      </p>
      <br></br>
    </div>
  );
};

const Second2: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const storyStatus: StoryStatusDict = location.state?.storyStatus || {
    currentStatus: 2,
    maxStatus: 2,
    second1Status: 0,
    second2Status: 0,
    second3Status: 0,
    second4Status: 0,
    second5Status: 0,
    second6Status: 0,
    second7Status: 0,
    second8Status: 0,
    second9Status: 0,
    second10Status: 0,
    second11Status: 0,
    second12Status: 0,
    secondId: 0,
  };
  const initialSecond2State = storyStatus["second2Status"] === 10 ? 10 : 0;
  const [userAnswer, setUserAnswer] = useState("");
  const [second2State, setSecond2State] = useState(initialSecond2State);
  const [count, setCount] = useState(second2State >= 8 ? 8 : 0);
  const [wrongMessage, setWrongMessage] = useState("");
  let nowStateText: String = `現在の正解数: ${count}`;

  const handleAnswerSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (yourAnswerList.includes(userAnswer)) {
      setWrongMessage("その答えは既に送られているようです");
    } else if (answerList.includes(userAnswer)) {
      setSecond2State(second2State + 1);
      setCount((prev) => prev + 1);
      yourAnswerList.push(userAnswer);
      setUserAnswer("");
      setWrongMessage("");
    } else {
      setWrongMessage("答えが正しくないようです");
    }
  };

  const toSecond = () => {
    storyStatus["currentStatus"] = 2;
    if (second2State === 8) {
      storyStatus["second2Status"] = 10;
      Cookies.set("clear_rechord_second_2", "true", { expires: 36500 });
    }
    navigate("/story/second", { state: { storyStatus } });
  };

  const toHint = () => {
    window.open(
      "https://anothervision.tokyo/special/rechord-web/rechord-hint.html",
      "_blank",
      "noopener,noreferrer"
    );
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const v = params.get("cleared");
    if (v) {
      setSecond2State(8);
      storyStatus["second2Status"] = 10;
      Cookies.set("clear_rechord_second_2", "true", { expires: 36500 });
    }
  }, []);

  return (
    <div className="sec2-container">
      <br></br>
      <h2 className="text">2nd 壮年期の記憶3</h2>
      <h3 className="text">8枚の紙から連想される、</h3>
      <h3 className="text">8種類の鳥を答えよ</h3>
      <p>{nowStateText}</p>
      あなたの解答:
      {yourAnswerList.map((data, index) => (
        <div key={index}>{data}</div>
      ))}
      <br></br>
      <form onSubmit={handleAnswerSubmit} className="sec2-form">
        <input
          type="text"
          value={userAnswer}
          onChange={(e) => setUserAnswer(e.target.value)}
          placeholder="ひらがなで入力"
          disabled={second2State === 8 || storyStatus["second2Status"] === 10}
          className="sec2-answer-input"
        />
        <button
          type="submit"
          disabled={second2State === 8 || storyStatus["second2Status"] === 10}
          className="sec2-submit-button"
        >
          送信
        </button>
      </form>
      <p>{wrongMessage}</p>
      {(second2State === 8 || second2State === 10) && clearedStory()}
      <button onClick={toSecond} className="button">
        2ndに戻る
      </button>
      <br></br>
      {/* <button onClick={toHint} className='button'>ヒント</button> */}
      <br></br>
    </div>
  );
};

export default Second2;
