// src/components/StorySecondPage.tsx
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './StorySecondPage.css';
import { StoryStatusDict } from '../utils/DictionaryTypes';
import Cookies from 'js-cookie';

const initialStatus = {
    currentStatus: 2,
    maxStatus: 2,
    map: -1,
    second1Status: 0,
    second2Status: 0,
    second3Status: 0,
    second4Status: 0,
    second5Status: 0,
    second6Status: 0,
    second7Status: 0,
    second8Status: 0,
    second9Status: 0,
    second10Status: 0,
    second11Status: 0,
    second12Status: 0,
    secondId: 0,
    thirdStatus: 0,
}

const StorySecondPage: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [storyStatus, setStoryStatus] = useState<StoryStatusDict>(location.state?.storyStatus || initialStatus)

    // maxStatusでは進行状況の最高到達度を記録、下記のuseEffectで設定する際に使用する
    const maxStatusTmp = storyStatus['maxStatus'];

    useEffect(() => {
      setStoryStatus({
        currentStatus: 2,
        maxStatus: maxStatusTmp,
        map: Cookies.get("map_browse") !== undefined ? 0 : -1,
        second1Status: Cookies.get("clear_rechord_second_1") !== undefined ? 10 : 0,
        second2Status: Cookies.get("clear_rechord_second_2") !== undefined ? 10 : 0,
        second3Status: Cookies.get("clear_rechord_second_3") !== undefined ? 10 : 0,
        second4Status: Cookies.get("clear_rechord_second_4") !== undefined ? 10 : 0,
        second5Status: Cookies.get("clear_rechord_second_5") !== undefined ? 10 : 0,
        second6Status: Cookies.get("clear_rechord_second_6") !== undefined ? 10 : 0,
        second7Status: Cookies.get("clear_rechord_second_7") !== undefined ? 10 : 0,
        second8Status: Cookies.get("clear_rechord_second_8") !== undefined ? 10 : 0,
        second9Status: Cookies.get("clear_rechord_second_9") !== undefined ? 10 : 0,
        second10Status: Cookies.get("clear_rechord_second_10") !== undefined ? 10 : 0,
        second11Status: Cookies.get("clear_rechord_second_11") !== undefined ? 10 : 0,
        second12Status: Cookies.get("clear_rechord_second_12") !== undefined ? 10 : 0,
        secondId: 0,
        thirdStatus: Cookies.get("third_half_check") !== undefined ? 1 : 0,
    })
    }, [])

    const [wrongMessage, setWrongMessage] = useState<string>('');
    const [toThirdFlag, setToThirdFlag] = useState<boolean>(storyStatus['maxStatus'] > 2 ? true : false);
    const [completeFlag, setCompleteFlag] = useState<boolean>(false);

    const imgSrc1 = storyStatus['second8Status'] === 10 ? '/unlockedImage/story/second-button/幼少期の記憶1.png' : '/story/second-button/幼少期の記憶1.png';
    const imgSrc2 = storyStatus['second1Status'] === 10 ? '/unlockedImage/story/second-button/幼少期の記憶2.png' : '/story/second-button/幼少期の記憶2.png';
    const imgSrc3 = storyStatus['second9Status'] === 10 ? '/unlockedImage/story/second-button/幼少期の記憶3.png' : '/story/second-button/幼少期の記憶3.png';
    const imgSrc4 = storyStatus['second7Status'] === 10 ? '/unlockedImage/story/second-button/青年期の記憶1.png' : '/story/second-button/青年期の記憶1.png';
    const imgSrc5 = storyStatus['second10Status'] === 10 ? '/unlockedImage/story/second-button/青年期の記憶2.png' : '/story/second-button/青年期の記憶2.png';
    const imgSrc6 = storyStatus['second4Status'] === 10 ? '/unlockedImage/story/second-button/青年期の記憶3.png' : '/story/second-button/青年期の記憶3.png';
    const imgSrc7 = storyStatus['second5Status'] === 10 ? '/unlockedImage/story/second-button/壮年期の記憶1.png' : '/story/second-button/壮年期の記憶1.png';
    const imgSrc8 = storyStatus['second11Status'] === 10 ? '/unlockedImage/story/second-button/壮年期の記憶2.png' : '/story/second-button/壮年期の記憶2.png';
    const imgSrc9 = storyStatus['second2Status'] === 10 ? '/unlockedImage/story/second-button/壮年期の記憶3.png' : '/story/second-button/壮年期の記憶3.png';
    const imgSrc10 = storyStatus['second12Status'] === 10 ? '/unlockedImage/story/second-button/老年期の記憶1.png' : '/story/second-button/老年期の記憶1.png';
    const imgSrc11 = storyStatus['second3Status'] === 10 ? '/unlockedImage/story/second-button/老年期の記憶2.png' : '/story/second-button/老年期の記憶2.png';
    const imgSrc12 = storyStatus['second6Status'] === 10 ? '/unlockedImage/story/second-button/老年期の記憶3.png' : '/story/second-button/老年期の記憶3.png';
    const navigationToMap = (storyStatus: StoryStatusDict) => {
        if (storyStatus['map'] !== -1) {
            navigate('/map', { state: { storyStatus } });
        } else {
            navigate('/map/tmp', { state: { storyStatus } });
        }
    }
    // 第三部への進行条件をチェック
    const secondCheck = () => {
        if (storyStatus['maxStatus'] > 2) {
            return true
        } else {
            let secondCount: number = 0;
            for (const key in storyStatus) {
                if (storyStatus.hasOwnProperty(key)) {
                    if (key === 'currentStatus' || key === 'maxStatus' || key === 'secondId' || key === 'map' || storyStatus['thirdStatus']) continue;
                    if (storyStatus[key as keyof StoryStatusDict] === 10) {
                        secondCount += storyStatus[key as keyof StoryStatusDict];
                    }
                }
            }

            if (storyStatus['maxStatus'] === 2 && secondCount >= 30) {
                storyStatus['maxStatus'] = 3;
                return true;
            } else {
                return false;
            }
        }
      };

    // 第二部の各ストーリーページへナビゲート
    const goToSecondPart = (partNumber: number) => {
        storyStatus['secondId'] = partNumber;
        const targetStatus = `second${partNumber}Status` as keyof StoryStatusDict; 
        if (storyStatus[targetStatus] === 10) {
            navigate(`/story/second/${partNumber}`, { state: { storyStatus } }); 
        } else {
            navigate('/story/second/tmp', { state: { storyStatus } }); 
        }
    };

    useEffect(() => {
        let secondCount: number = 0;
        for (const key in storyStatus) {
            if (storyStatus.hasOwnProperty(key)) {
                if (key === 'currentStatus' || key === 'maxStatus' || key === 'secondId' || key === 'map' || key === 'thirdStatus') continue;
                if (storyStatus[key as keyof StoryStatusDict] === 10) {
                    secondCount += storyStatus[key as keyof StoryStatusDict];
                }
            }
        }
        if (secondCount >= 30) {
            setToThirdFlag(true);
        }
        if (secondCount >= 120 && storyStatus['maxStatus'] === 4) {
            setCompleteFlag(true);
        }
    }, [storyStatus])
    
    const toThird = () => {
        if (secondCheck()) {
            storyStatus['currentStatus'] = 3;
            navigate('/story/third', { state: { storyStatus } });
        } else {
            setWrongMessage('まだ先に進めないみたいだ');
        }
    };

    const toFirst = () => {
        storyStatus['currentStatus'] = 1;
        navigate('/story/first', { state: { storyStatus } });
    }

    const toHint = () => {
        window.open('https://anothervision.tokyo/special/rechord-web/rechord-hint.html', '_blank', 'noopener,noreferrer');
    };

    return (
        <div className='second-page'>
            <br></br>
            <button onClick={() => navigationToMap(storyStatus)} className='button'>地図</button>
            {/* <button onClick={() => toHint(storyStatus)} className='button'>ヒントページ</button> */}
            <h2 className='text-center'>2nd Step</h2>
            {!toThirdFlag && <h3 className='text-center'>謎を解き、3つの記憶を取り戻そう！</h3>}
            {(toThirdFlag && !completeFlag) && <h3 className='text-center'>3つの記憶を取り戻した！先に進めそうだ！</h3>}
            {(toThirdFlag && completeFlag) && <h3 className='text-center'>完全制覇！おめでとう！</h3>}
            <img src={imgSrc1} alt='1' className='stage-select' onClick={() => goToSecondPart(8)}/>
            <img src={imgSrc2} alt='2' className='stage-select' onClick={() => goToSecondPart(1)}/>
            <img src={imgSrc3} alt='3' className='stage-select' onClick={() => goToSecondPart(9)}/>
            <img src={imgSrc4} alt='4' className='stage-select' onClick={() => goToSecondPart(7)}/>
            <img src={imgSrc5} alt='5' className='stage-select' onClick={() => goToSecondPart(10)}/>
            <img src={imgSrc6} alt='6' className='stage-select' onClick={() => goToSecondPart(4)}/>
            <img src={imgSrc7} alt='7' className='stage-select' onClick={() => goToSecondPart(5)}/>
            <img src={imgSrc8} alt='8' className='stage-select' onClick={() => goToSecondPart(11)}/>
            <img src={imgSrc9} alt='9' className='stage-select' onClick={() => goToSecondPart(2)}/>
            <img src={imgSrc10} alt='10' className='stage-select' onClick={() => goToSecondPart(12)}/>
            <img src={imgSrc11} alt='11' className='stage-select' onClick={() => goToSecondPart(3)}/>
            <img src={imgSrc12} alt='12' className='stage-select' onClick={() => goToSecondPart(6)}/>
            {/* {[1, 2, 3, 4, 5, 6].map((num) => (
                <button key={num} onClick={() => goToSecondPart(num)}>
                    {num}
                </button>
            ))}
            <br></br><br></br>
            {[7, 8, 9, 10, 11, 12].map((num) => (
                <button key={num} onClick={() => goToSecondPart(num)}>
                    {num}
                </button>
            ))} */}
            <br></br>
            <p>{wrongMessage}</p>
            <button onClick={toThird} className='submit-button'>次に進む</button>
            <br></br>
            <button onClick={toFirst} className='submit-button'>戻る</button>
            <br></br>
            {/* <button onClick={toHint} className='button'>ヒント</button> */}
            <br></br>
        </div>
    );
};

export default StorySecondPage;