// src/Story/Second8.tsx
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./Second8.css";
import { StoryStatusDict } from "../../utils/DictionaryTypes";
import Cookies from "js-cookie";

const clearedStory = () => {
  return (
    <div>
      <p>
        ピアノの美しい旋律が聞こえる。弾いているのは、通っていたピアノ教室の先生。
      </p>
      <p>後に私が「師匠」と敬愛することになる人だ。</p>
      <br></br>
      <p>
        まだピアノや音楽がそこまで好きなわけではなかったから、すぐに集中が途切れてしまう。
      </p>
      <p>そんなとき、師匠は「休憩しよっか。」と言って色々なことをし始める。</p>
      <br></br>
      <p>
        一番多かったのは、楽器の演奏だった。楽器の種類は、ピアノやバイオリン、ギターやウクレレ、さらには見たこともないような楽器のときもあった。何を弾いても上手だったし、演奏を聞くと楽しい気分になれる。
      </p>
      <br></br>
      <p>
        音楽と無関係なものを持ってくることも多かった。当時流行っていたカードゲームを持ってきて、「一緒にやろう。」と言ってきた。
      </p>
      <p>
        カードゲームに関しては、師匠は驚くほど弱かった。負けるたびに「もう一回！」って言って、レッスンがなかなか再開できなかったこともあった。
      </p>
      <br></br>
      <p>
        休憩が終わる頃には私も笑顔になっていて、その後のレッスンも楽しく続けられた。
      </p>
      <p>
        子供の頃の私にとって、師匠は先生であり、一番の遊び相手であり、新たな世界への導き手だった。
      </p>
      <br></br>
    </div>
  );
};

const Second8: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const storyStatus: StoryStatusDict = location.state?.storyStatus || {
    currentStatus: 2,
    maxStatus: 2,
    second1Status: 0,
    second2Status: 0,
    second3Status: 0,
    second4Status: 0,
    second5Status: 0,
    second6Status: 0,
    second7Status: 0,
    second8Status: 0,
    second9Status: 0,
    second10Status: 0,
    second11Status: 0,
    second12Status: 0,
    secondId: 0,
  };

  const [wrongMessage, setWrongMessage] = useState<string>("");
  const [introTextFlag, setIntroTextFlag] = useState(true);
  const [quest1Flag, setQuest1Flag] = useState(false);
  const [quest2Flag, setQuest2Flag] = useState(false);
  const [quest3Flag, setQuest3Flag] = useState(false);
  const [userAnswer, setUserAnswer] = useState("");
  const [story8State, setStory8State] = useState(storyStatus["second8Status"]);
  const [secondYourAnswerList, setSecondYourAnswerList] = useState<string[]>(
    []
  );
  const [secondAttackCount, setSecondAttackCount] = useState(0);
  const secondAnswerList = ["くま", "たら", "いか"];

  const toggleIntroText = () => {
    setIntroTextFlag(!introTextFlag);
  };

  const toggleQuest1 = () => {
    setQuest1Flag(!quest1Flag);
  };

  const toggleQuest2 = () => {
    setQuest2Flag(!quest2Flag);
  };

  const toggleQuest3 = () => {
    setQuest3Flag(!quest3Flag);
  };

  const handleAnswerSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (story8State === 0) {
      if (userAnswer === "もの") {
        setWrongMessage("");
        setUserAnswer("");
        setStory8State(story8State + 1);
      } else {
        setWrongMessage("依頼の条件に合致していないようです");
      }
    } else if (story8State === 1) {
      if (
        secondAnswerList.includes(userAnswer) &&
        !secondYourAnswerList.includes(userAnswer)
      ) {
        if (secondAttackCount === 0) {
          setWrongMessage("");
          setSecondAttackCount(secondAttackCount + 1);
          setSecondYourAnswerList([...secondYourAnswerList, userAnswer]);
        } else if (secondAttackCount === 1) {
          setWrongMessage("");
          setSecondAttackCount(secondAttackCount + 1);
          setSecondYourAnswerList([...secondYourAnswerList, userAnswer]);
        } else {
          setWrongMessage("");
          setStory8State(story8State + 1);
        }
        setUserAnswer("");
      } else if (secondYourAnswerList.includes(userAnswer)) {
        setWrongMessage("その答えは既に送られているようです");
      } else {
        setWrongMessage("依頼の条件に合致していないようです");
      }
    } else if (story8State === 2) {
      if (userAnswer === "せんりつ") {
        setWrongMessage("");
        setUserAnswer("");
        setStory8State(10);
        Cookies.set("clear_rechord_second_8", "true", { expires: 7 });
      } else {
        setWrongMessage("依頼の条件に合致していないようです");
      }
    }
  };

  const toSecond = () => {
    storyStatus["currentStatus"] = 2;
    storyStatus["second8Status"] = story8State;
    navigate("/story/second", { state: { storyStatus } });
  };

  const toHint = () => {
    window.open(
      "https://anothervision.tokyo/special/rechord-web/rechord-hint.html",
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <div className="sec8-container">
      <br></br>
      <h2 className="text">2nd 幼少期の記憶1</h2>
      <br></br>
      <button onClick={toggleIntroText} className="button">
        {introTextFlag ? "説明を閉じる" : "説明"}
      </button>
      <br></br>
      <br></br>
      {introTextFlag && (
        <div>
          <div className="padded">
            <h3 className="text">このゲームについて</h3>
            <p>
              カードを使ってモンスターを討伐するゲームです。3つの討伐依頼をこなすとクリアとなります。
            </p>
          </div>
          <br></br>
          <div className="padded">
            <h3 className="text">カードについて</h3>
            <p>まず、封筒の中身を全て取り出し、目の前に置いてください。</p>
            <p>
              討伐に用いる「カード」は、そのように縦に長い長方形で、平たい形をしています。また、いまあなたの目の前にあるものが、今回使うカードの全てです。それらを使いこなして、モンスターの討伐をしましょう。
            </p>
          </div>
          <br></br>
          <div className="padded">
            <h3 className="text">カードの攻撃力について</h3>
            <p>
              どのカードも、カタカナ3文字の名前で呼ばれています。その名前を下の「対応表」に対応させた3桁の数が攻撃力です。例えば「クルマ」の攻撃力は「329」となります。いまのうちに求めてメモしておきましょう。
              <br></br>
              <span style={{ color: "blue" }}>１ ２ ３ ４ ５ ６ ７ ８ ９</span>
              <br></br>
              <span style={{ color: "blue" }}>ス ル ク ラ タ カ イ ホ マ</span>
            </p>
          </div>
          <br></br>
          <div className="padded">
            <h3 className="text">カードの属性について</h3>
            <p>
              今回使えるカードの属性は「電気」「メタル」「水」の3種類あります。属性は、攻撃力によって次のように決まります。これもいまのうちに求めてメモしましょう。
              <br></br>
              <span style={{ color: "blue" }}>100~199 電気</span>
              <br></br>
              <span style={{ color: "blue" }}>200~699 メタル</span>
              <br></br>
              <span style={{ color: "blue" }}>700~999 水</span>
            </p>
          </div>
          <br></br>
          <div className="padded">
            <h3 className="text">討伐について</h3>
            <p>
              討伐対象によって「使えるカードの属性」「攻撃できる回数」が決まっています。あるカードを使って攻撃すると、そのカードの攻撃力の分だけ対象の体力が減少します。対象の体力が0に達したら、討伐成功です
            </p>
            <p>同じ対象に対して同じカードで2回攻撃することはできません。</p>
          </div>
          <br></br>
          <div className="padded">
            <h3 className="text">攻撃について</h3>
            <p>
              討伐対象のモンスターが出す謎を、攻撃に用いるカードを使って解き、その答えを送信することで攻撃ができます。
            </p>
            <p>謎は、謎を出したモンスターの付近に出現します。</p>
          </div>
          <br></br>
          <p>
            説明は以上です。この説明は「説明を表示する」ボタンからいつでも見直す事ができます。それではさっそく1つめの依頼をこなしていきましょう！
          </p>
          <br></br>
        </div>
      )}
      <button onClick={toggleQuest1} className="button">
        {quest1Flag ? "1つ目の依頼を閉じる" : "1つ目の依頼を表示"}
      </button>
      <br></br>
      {quest1Flag && (
        <div>
          <li>討伐対象: 目が一つのモンスター</li>
          <li>使えるカードの属性: メタルのみ</li>
          <li>攻撃できる回数: 1</li>
          <li>体力: 500</li>
          {story8State >= 1 && <h3 className="text">討伐成功！</h3>}
          <br></br>
          {story8State === 0 && (
            <form onSubmit={handleAnswerSubmit} className="sec8-form">
              <input
                type="text"
                value={userAnswer}
                onChange={(e) => setUserAnswer(e.target.value)}
                placeholder="ひらがなで入力"
                className="sec8-answer-input"
              />
              <button type="submit" className="sec8-submit-button">
                送信
              </button>
            </form>
          )}
        </div>
      )}
      {story8State === 0 && <p>{wrongMessage}</p>}
      {story8State >= 1 && (
        <button onClick={toggleQuest2} className="button">
          {quest2Flag ? "2つ目の依頼を閉じる" : "2つ目の依頼を表示"}
        </button>
      )}
      <br></br>
      {quest2Flag && (
        <div>
          <li>討伐対象: 岩型の大きなモンスター</li>
          <li>使えるカードの属性: 水またはメタル</li>
          <li>攻撃できる回数: 3</li>
          <li>体力: 1500</li>
          {secondAttackCount >= 1 && (
            <h3 className="text">1回目の攻撃成功！</h3>
          )}
          {secondAttackCount >= 2 && (
            <h3 className="text">2回目の攻撃成功！</h3>
          )}
          {story8State >= 2 && <h3 className="text">討伐成功！</h3>}
          <br></br>
          {story8State === 1 && (
            <form onSubmit={handleAnswerSubmit} className="sec8-form">
              <input
                type="text"
                value={userAnswer}
                onChange={(e) => setUserAnswer(e.target.value)}
                placeholder="ひらがなで入力"
                className="sec8-answer-input"
              />
              <button type="submit" className="sec8-submit-button">
                送信
              </button>
            </form>
          )}
        </div>
      )}
      {story8State === 1 && <p>{wrongMessage}</p>}
      {story8State >= 2 && (
        <button onClick={toggleQuest3} className="button">
          {quest3Flag ? "3つ目の依頼を閉じる" : "3つ目の依頼を表示"}
        </button>
      )}
      {quest3Flag && (
        <div>
          <li>討伐対象: 水の幽霊のモンスター </li>
          <li>使えるカードの属性: 電気のみ</li>
          <li>攻撃できる回数: 1</li>
          <li>体力: 190</li>
          {story8State >= 3 && <h3 className="text">討伐成功！</h3>}
          <br></br>
          {story8State === 2 && (
            <form onSubmit={handleAnswerSubmit} className="sec8-form">
              <input
                type="text"
                value={userAnswer}
                onChange={(e) => setUserAnswer(e.target.value)}
                placeholder="ひらがなで入力"
                className="sec8-answer-input"
              />
              <button type="submit" className="sec8-submit-button">
                送信
              </button>
            </form>
          )}
          <br></br>
        </div>
      )}
      {story8State === 2 && <p>{wrongMessage}</p>}
      {story8State === 10 && clearedStory()}
      <br></br>
      <br></br>
      <button onClick={toSecond} className="button">
        2ndに戻る
      </button>
      <br></br>
      {/* <button onClick={toHint} className='button'>ヒント</button> */}
      <br></br>
    </div>
  );
};

export default Second8;
