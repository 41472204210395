// src/components/OpeningPage.tsx
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './OpeningPage.css';
import { StoryStatusDict } from '../utils/DictionaryTypes';

const OpeningPage: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const storyStatus: StoryStatusDict = location.state?.storyStatus || {
        currentStatus: 0,
        maxStatus: 0,
        map: -1,
        second1Status: 0,
        second2Status: 0,
        second3Status: 0,
        second4Status: 0,
        second5Status: 0,
        second6Status: 0,
        second7Status: 0,
        second8Status: 0,
        second9Status: 0,
        second10Status: 0,
        second11Status: 0,
        second12Status: 0,
        secondId: 0,
        thirdStatus: 0,
    };

    const goToStoryPage = () => {
        storyStatus['currentStatus'] = 1;
        if (storyStatus['maxStatus'] === 0) {
            storyStatus['maxStatus'] = 1;
        }
        navigate('/story/first', { state: { storyStatus } });
    };

    return (
        <div className='opening'>
            <img src='/story/Rechord_MV.png' alt='Re:chord MV' className='Rechord-MV' />
            <br></br>
            {/* <h1 className='text'>注意事項</h1>
            <p>ここは最初のページです。</p>
            <p>注意事項等がここに追加されます。</p> */}
            <img src='/story/attention.png' alt='attention' className='attention-img'/>
            <br></br><br></br>
            {storyStatus['maxStatus'] === 0 && (
                <button onClick={goToStoryPage} className='button'>物語を始める</button>
            )}
            {storyStatus['maxStatus'] !== 0 && (
                <button onClick={goToStoryPage} className='button'>物語に戻る</button>
            )}
        </div>
    );
};

export default OpeningPage;
