// src/Story/Second7.tsx
import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Second7.css';
import { StoryStatusDict } from '../../utils/DictionaryTypes';
import Cookies from 'js-cookie';

const clearedStory = () => {
    return (
        <div>
            <p>小鳥のさえずりが聞こえる道を歩く。</p>
            <p>私は晴れて、第一志望だった音楽大学の作曲科に進学していた。</p>
            <br></br>
            <p>ある日、いつものようにレッスンを終えた帰り道で、</p>
            <p>道沿いのカフェから聴こえた美しい音色に、思わず足を止める。</p>
            <br></br>
            <p>外からこっそりと中を伺うと、ピアノとフルートの二重奏だった。</p>
            <p>二つの楽器の豊かな音色が合わさって生み出される和音、そして「歌うように弾く」を体現するような演奏に息を呑む。</p>
            <br></br>
            <p>曲が終わり、無音の時間が流れる。</p>
            <p>胸が熱くなり、気が付くと涙があふれていた。</p>
            <p>こんな感情になったのは久しぶりだった。</p>
            <br></br>
            <p>音大に入学してから、ずっと周りの評価に囚われていた。</p>
            <p>本来、音楽はもっと楽しんでいいものではなかったか。</p>
            <p>音楽を心から楽しむ余裕を失っていたのではないか。</p>
            <br></br>
            <p>私に希望の音が降り注がれた瞬間だった。</p>
            <br></br>
        </div>
    );
}

// 中謎7thのデータ
const Second7Data = [
    { text: '「さっきこんなものを拾ったんだけど・・・一体これはなんだろう？何枚かあるから、順番に見ていってね」', imgSrc: '/story/second/7-1.png', answer: 'ふるーと', validLength: 4 },
    { text: '', imgSrc: '/story/second/7-2.png', answer: 'ねいろ', validLength: 3 },
    { text: '', imgSrc: '/story/second/7-3.png', answer: 'きぼう', validLength: 3 },
    { text: '', imgSrc: '/story/second/7-6.png', answer: 'むおん', validLength: 3 },
    { text: '', imgSrc: '/story/second/7-5.png', answer: 'そんぐ', validLength: 3 },
    { text: '', imgSrc: '/story/second/7-4.png', answer: 'ぴあの', validLength: 3 },
    { text: '「これが最後みたい！でも私には何のことかさっぱりだよ・・・」', imgSrc: '/story/second/7-7.png', answer: 'えもーしょん', validLength: 6 },
];
  
const Second7: React.FC = () => {
    const [userAnswer, setUserAnswer] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const storyStatus: StoryStatusDict = location.state?.storyStatus || {
        currentStatus: 2,
        maxStatus: 2,
        second1Status: 0,
        second2Status: 0,
        second3Status: 0,
        second4Status: 0,
        second5Status: 0,
        second6Status: 0,
        second7Status: 0,
        second8Status: 0,
        second9Status: 0,
        second10Status: 0,
        second11Status: 0,
        second12Status: 0,
        secondId: 0
    };

    const [second7State, setSecond7State] = useState(storyStatus['second7Status']);
    const [wrongMessage, setWrongMessage] = useState('');
  
    const handleAnswerSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        if (second7State === 10) { return };
        e.preventDefault();
        if (second7State < Second7Data.length - 1) {
            if (userAnswer.length === Second7Data[second7State].validLength) {
                setSecond7State(second7State + 1)
                setUserAnswer('');
                setWrongMessage('');
            } else {
                setWrongMessage('文字数が不適切です');
            }
        } else {
            // 最終問題の正誤判定
            if (userAnswer === 'えもーしょん') {
                setSecond7State(10);
                setWrongMessage('');
                Cookies.set('clear_rechord_second_7', "true", { expires:7 });
            } else {
                setWrongMessage('答えが正しくないようです');
            }
        }
    };

    const toSecond = () => {
        storyStatus['currentStatus'] = 2;
        storyStatus['second7Status'] = second7State;
        navigate('/story/second', { state: { storyStatus } });
    }

    const toHint = () => {
        window.open('https://anothervision.tokyo/special/rechord-web/rechord-hint.html', '_blank', 'noopener,noreferrer');
    };
  
    return (
        <div className="sec7-container">
            <br></br>
            <h2 className='text'>2nd 青年期の記憶1</h2>
            {Second7Data.slice(0, second7State + 1).map((data, index) => (
            <div key={index}>
                <div className="sec7-text">{data.text}</div>
                <img src={data.imgSrc} alt={`謎 ${index + 1}`} className="sec7-image" />
            </div>
            ))}
            <form onSubmit={handleAnswerSubmit} className="sec7-form">
                <input
                    type="text"
                    value={userAnswer}
                    onChange={(e) => setUserAnswer(e.target.value)}
                    placeholder='ひらがなで入力'
                    disabled={second7State === 10}
                    className="sec7-answer-input"
                />
                <button type="submit" disabled={second7State === 10} className="sec7-submit-button">送信</button>
            </form>
            <p>{wrongMessage}</p>
            <br></br>
            {second7State === 10 && (
                clearedStory()
            )}
            <button onClick={toSecond} className='button'>2ndに戻る</button>
            <br></br>
            {/* <button onClick={toHint} className='button'>ヒント</button> */}
            <br></br>
        </div>
    );
};
  
export default Second7;
