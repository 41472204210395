// src/Story/Second5.tsx
import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Second5.css'
import { StoryStatusDict } from '../../utils/DictionaryTypes';
import Cookies from 'js-cookie';

const clearedStory = () => {
    return (
        <div>
            <p>エキゾチックな音楽、部屋に漂うスパイスの香り、自国では見かけない品種の花々。</p>
            <p>コレクション部屋にいると、いつでも安らかな気分になれる。</p>
            <p>私を取り囲むものは全て、旅をして手に入れたものだった。</p>
            <br></br>
            <p>楽曲制作に詰まったとき、私はいつも旅に出る。</p>
            <p>初めてスランプに陥ったとき、師匠と共に行ったヨーロッパは一生の思い出だ。</p>
            <br></br>
            <p>引きこもっていては得られない、画面越しでは伝わらない、本物の世界。</p>
            <p>現地を訪れることで得た様々な知識や経験は、いつも私を生まれ変わらせてくれる。</p>
            <br></br>
            <p>旅は、私の人生において欠かせない存在なのだ。</p>
            <br></br>
        </div>
    )
}

const Second5: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const storyStatus: StoryStatusDict = location.state?.storyStatus || {
        currentStatus: 2,
        maxStatus: 2,
        second1Status: 0,
        second2Status: 0,
        second3Status: 0,
        second4Status: 0,
        second5Status: 0,
        second6Status: 0,
        second7Status: 0,
        second8Status: 0,
        second9Status: 0,
        second10Status: 0,
        second11Status: 0,
        second12Status: 0,
        secondId: 0
    };

    const textInst = "謎を解き進め、魔王を倒せ！";
    const imgMiddle = "/story/second/5-1.png";
    const imgLast = "/story/second/5-2.png";

    const [wrongMessage, setWrongMessage] = useState<string>('');
    const [input11, setInput11] = useState<string>(storyStatus['second5Status'] >= 4 ? 'ようかん' :'');
    const [input12, setInput12] = useState<string>(storyStatus['second5Status'] >= 4 ? 'かりすま' : '');
    const [input13, setInput13] = useState<string>(storyStatus['second5Status'] >= 4 ? 'たくしー' : '');
    const [input14, setInput14] = useState<string>(storyStatus['second5Status'] >= 4 ? 'ういんく' :'');
    const [input2, setInput2] = useState<string>(storyStatus['second5Status'] >= 5 ? 'かくしん': '');
    const [input3, setInput3] = useState<string>(storyStatus['second5Status'] === 10 ? 'とーん': '');
    const [isLocked1, setIsLocked1] = useState<boolean[]>(storyStatus['second5Status'] >= 4 ? [true, true, true, true] : [false, false, false, false]);
    const [second5State, setSecond5State] = useState<number>(storyStatus['second5Status'] >= 4 ? storyStatus['second5Status'] : 0)

    const handleSubmit11 = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (input11 === 'たくしー') {
            setWrongMessage('');
            const updatedIsLocked = [...isLocked1];
            updatedIsLocked[0] = true;
            setIsLocked1(updatedIsLocked);
            setSecond5State(prev => prev + 1);
        } else {
            setWrongMessage('答えが正しくないようです');
            setInput11('');
        }
    }

    const handleSubmit12 = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (input12 === 'ういんく') {
            setWrongMessage('');
            const updatedIsLocked = [...isLocked1];
            updatedIsLocked[1] = true;
            setIsLocked1(updatedIsLocked);
            setSecond5State(prev => prev + 1);
        } else {
            setWrongMessage('答えが正しくないようです');
            setInput12('');
        }
    }

    const handleSubmit13 = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (input13 === 'ようかん') {
            setWrongMessage('');
            const updatedIsLocked = [...isLocked1];
            updatedIsLocked[2] = true;
            setIsLocked1(updatedIsLocked);
            setSecond5State(prev => prev + 1);
        } else {
            setWrongMessage('答えが正しくないようです');
            setInput13('');
        }
    }

    const handleSubmit14 = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (input14 === 'かりすま') {
            setWrongMessage('');
            const updatedIsLocked = [...isLocked1];
            updatedIsLocked[3] = true;
            setIsLocked1(updatedIsLocked);
            setSecond5State(prev => prev + 1);
        } else {
            setWrongMessage('答えが正しくないようです');
            setInput14('');
        }
    }

    const handleAnswerSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (second5State === 4) {
            if (input2 === 'かくしん') {
                setWrongMessage('');
                setSecond5State(prev => prev + 1);
            } else {
                setWrongMessage('答えが正しくないようです');
            }
        }
        else if (second5State === 5) {
            if (input3 === 'とーん') {
                setWrongMessage('');
                setSecond5State(10);
                Cookies.set('clear_rechord_second_5', "true", { expires:7 });
            } else {
                setWrongMessage('答えが正しくないようです');
            }
        }
        else if (second5State > 5) {
            setWrongMessage('その答えは既に送られているようです');
        }
    }

    const toSecond = () => {
        storyStatus['currentStatus'] = 2;
        storyStatus['second5Status'] = second5State;
        navigate('/story/second', { state: { storyStatus } });
    }

    const toHint = () => {
        window.open('https://anothervision.tokyo/special/rechord-web/rechord-hint.html', '_blank', 'noopener,noreferrer');
    };

    return (
        <div className='sec5-container'>
            <br></br>
            <h2 className='text'>2nd 壮年期の記憶1</h2>
            <h3 className='text'>{textInst}</h3>
            <p>「じゅうじか」の謎</p>
            <form onSubmit={handleSubmit11}>
                <input
                    type="text"
                    value={input11}
                    onChange={(e) => setInput11(e.target.value)}
                    placeholder='ひらがなで入力'
                    className="sec5-answer-input"
                    disabled={isLocked1[0]}
                />
                <button type="submit" disabled={isLocked1[0]} style={{ backgroundColor: isLocked1[0] ? 'grey' : 'lightblue' }} className='sec5-submit-button'>
                    送信
                </button>
            </form>
            <p>「さかな」の謎</p>
            <form onSubmit={handleSubmit12}>
                <input
                    type="text"
                    value={input12}
                    onChange={(e) => setInput12(e.target.value)}
                    placeholder='ひらがなで入力'
                    className="sec5-answer-input"
                    disabled={isLocked1[1]}
                />
                <button type="submit" disabled={isLocked1[1]} style={{ backgroundColor: isLocked1[1] ? 'grey' : 'lightblue' }} className='sec5-submit-button'>
                    送信
                </button>
            </form>
            <p>「ほうせき」の謎</p>
            <form onSubmit={handleSubmit13}>
                <input
                    type="text"
                    value={input13}
                    onChange={(e) => setInput13(e.target.value)}
                    placeholder='ひらがなで入力'
                    className="sec5-answer-input"
                    disabled={isLocked1[2]}
                />
                <button type="submit" disabled={isLocked1[2]} style={{ backgroundColor: isLocked1[2] ? 'grey' : 'lightblue' }} className='sec5-submit-button'>
                    送信
                </button>
            </form>
            <p>「にんげん」の謎</p>
            <form onSubmit={handleSubmit14}>
                <input
                    type="text"
                    value={input14}
                    onChange={(e) => setInput14(e.target.value)}
                    placeholder='ひらがなで入力'
                    className="sec5-answer-input"
                    disabled={isLocked1[3]}
                />
                <button type="submit" disabled={isLocked1[3]} style={{ backgroundColor: isLocked1[3] ? 'grey' : 'lightblue' }} className='sec5-submit-button'>
                    送信
                </button>
            </form>
            {second5State < 4 && <p>{wrongMessage}</p>}
            <br></br>
            {second5State >= 4 && (
                <div>
                    <img src={imgMiddle} alt='Middle' className='sec5-img'/>
                    <form onSubmit={handleAnswerSubmit} className='sec5-form'>
                        <input
                            type='text'
                            value={input2}
                            onChange={(e) => setInput2(e.target.value)}
                            placeholder='ひらがなで入力'
                            className='sec5-answer-input'
                            disabled={(second5State !== 4)}
                        />
                        <button type='submit' disabled={(second5State !== 4)} className='sec5-submit-button'>送信</button>
                    </form>
                </div>
            )}
            {second5State === 4 && <p>{wrongMessage}</p>}
            <br></br>
            {second5State >= 5 && (
                <div>
                    <img src={imgLast} alt='Last' className='sec5-img'/>
                    <form onSubmit={handleAnswerSubmit} className='sec5-form'>
                        <input
                            type='text'
                            value={input3}
                            onChange={(e) => setInput3(e.target.value)}
                            placeholder='ひらがなで入力'
                            className='sec5-answer-input'
                            disabled={(second5State !== 5)}
                        />
                        <button type='submit' disabled={(second5State !== 5)} className='sec5-submit-button'>送信</button>
                    </form>
                </div>
            )}
            {second5State === 5 && <p>{wrongMessage}</p>}
            <br></br>
            {(storyStatus['second5Status'] === 10 || second5State === 10) && (
                clearedStory()
            )}
            <br></br>
            <button onClick={toSecond} className='button'>2ndに戻る</button>
            <br></br>
            {/* <button onClick={toHint} className='button'>ヒント</button> */}
            <br></br>
        </div>
    )
}

export default Second5;