// src/Story/Second3.tsx
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./Second3.css";
import { StoryStatusDict } from "../../utils/DictionaryTypes";
import Cookies from "js-cookie";

const story1 = () => {
  return (
    <div>
      <p>そよ風が聞こえる。私は小高い丘の上にいた。</p>
      <p>
        雲一つない青空の下、黒いスーツに身を包んだ私は、真っ白な墓石の前に立つ。
      </p>
      <p>そこは、私の師が眠る場所だった。</p>
      <br></br>
      <p>彼女は私に音楽の『イロハのイ』を教えてくれた。</p>
      <p>
        「『ドレミファソラシド』は日本語なら『ハニホヘトイロハ』、英語なら『CDEFGABC』でしょ？音楽で言う『イロハのイ』は、『ラシドのラ』になるんじゃない？」
      </p>
      <p>変なことを言う人だった。</p>
      <br></br>
      <p>このまま、師匠との思い出にずっと浸っていたい。</p>
      <p>しかし、私にはまだやらなければならないことがある。</p>
      <p>彼女の墓に背を向け、私は一歩踏み出した。</p>
      <br></br>
    </div>
  );
};

const story2 = () => {
  return (
    <div>
      <p>あの日から、私はひたすら曲を作り続けた。</p>
      <br></br>
      <p>
        心躍るマーチ、一つ一つの旋律が美しく重なるコンチェルト、そして優雅だがどこか寂しげな雰囲気をまとわせたワルツ。クラシックのみならず、ジャズやブルース、ポップ・ミュージックにも挑戦した。一曲一曲、全力をかけて作曲した。
      </p>
      <br></br>
      <p>全ては一人前になるため。それだけだった。</p>
      <br></br>
      <p>一方で、「音楽を生み出すときは、自分が一番楽しくなきゃいけない。」</p>
      <p>という師匠の言葉も忘れなかった。</p>
      <p>常に美しく、そして楽しく、私は曲を作り続けた。</p>
      <br></br>
      <p>気づけば、私は世間からも大きく評価される作曲家になっていた。</p>
      <br></br>
      <p>※別の掲示物を参照して解きなおしてみよう。</p>
      <br></br>
    </div>
  );
};

const story3 = () => {
  return (
    <div>
      <p>私は再び師匠の墓を訪れた。</p>
      <p>
        前に来たときから10年が経っていた。次に師匠に会うのは一人前になったときだと、心に決めていたからだった。
      </p>
      <br></br>
      <p>眼を閉じて、手を合わせる。そして、師匠の魂に語りかける。</p>
      <br></br>
      <p>私はあなたの立派な教え子になれたでしょうか。</p>
      <p>
        私にとってのあなたのように、音楽の楽しさを人に伝えられるような作曲家に。
      </p>
      <br></br>
    </div>
  );
};

const Second3: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const storyStatus: StoryStatusDict = location.state?.storyStatus || {
    currentStatus: 2,
    maxStatus: 2,
    second1Status: 0,
    second2Status: 0,
    second3Status: 0,
    second4Status: 0,
    second5Status: 0,
    second6Status: 0,
    second7Status: 0,
    second8Status: 0,
    second9Status: 0,
    second10Status: 0,
    second11Status: 0,
    second12Status: 0,
    secondId: 0,
  };

  const textInst = "掲示物を見て謎を解こう";
  const [wrongMessage, setWrongMessage] = useState("");

  const [second3State, setSecond3State] = useState(
    storyStatus["second3Status"] === 10 ? 8 : 0
  );
  const keywordsPhase1 = ["わるつ", "ししとう", "まう", "とす"];
  const keywordsPhase2 = ["つーびーと", "うどん", "なえ", "ふぁん"];

  const [inputs1, setInputs1] = useState<string[]>(["", "", "", ""]);
  const [isLocked1, setIsLocked1] = useState<boolean[]>([
    false,
    false,
    false,
    false,
  ]);
  const [answeredKeywords1, setAnsweredKeyword1] = useState<string[]>([]);

  const [inputs2, setInputs2] = useState<string[]>(["", "", "", ""]);
  const [isLocked2, setIsLocked2] = useState<boolean[]>([
    false,
    false,
    false,
    false,
  ]);
  const [answeredKeywords2, setAnsweredKeyword2] = useState<string[]>([]);

  const handleSubmit1 = (index: number, e: React.FormEvent) => {
    e.preventDefault();
    const input = inputs1[index].trim();

    if (answeredKeywords1.includes(input)) {
      setWrongMessage("その答えは既に送られているようです");
      return;
    }

    if (keywordsPhase1.includes(input)) {
      setWrongMessage("");
      setAnsweredKeyword1([...answeredKeywords1, input]);
      const updatedIsLocked = [...isLocked1];
      updatedIsLocked[index] = true;
      setIsLocked1(updatedIsLocked);
      setSecond3State((prev) => prev + 1);
    } else {
      setWrongMessage("答えが正しくないようです");
    }
  };

  const handleChange1 = (index: number, value: string) => {
    const updatedInputs = [...inputs1];
    updatedInputs[index] = value;
    setInputs1(updatedInputs);
  };

  const handleSubmit2 = (index: number, e: React.FormEvent) => {
    e.preventDefault();
    const input = inputs2[index].trim();

    if (answeredKeywords2.includes(input)) {
      setWrongMessage("その答えは既に送られているようです");
      return;
    }

    if (keywordsPhase2.includes(input)) {
      setWrongMessage("");
      setAnsweredKeyword2([...answeredKeywords2, input]);
      const updatedIsLocked = [...isLocked2];
      updatedIsLocked[index] = true;
      setIsLocked2(updatedIsLocked);
      setSecond3State((prev) => prev + 1);
    } else {
      setWrongMessage("答えが正しくないようです");
    }
  };

  const handleChange2 = (index: number, value: string) => {
    const updatedInputs = [...inputs2];
    updatedInputs[index] = value;
    setInputs2(updatedInputs);
  };

  const toSecond = () => {
    storyStatus["currentStatus"] = 2;
    if (second3State === 8) {
      storyStatus["second3Status"] = 10;
      Cookies.set("clear_rechord_second_3", "true", { expires: 36500 });
    }
    navigate("/story/second", { state: { storyStatus } });
  };

  const toHint = () => {
    window.open(
      "https://anothervision.tokyo/special/rechord-web/rechord-hint.html",
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <div className="sec3-container">
      <br></br>
      <h2 className="text">2nd 老年期の記憶2</h2>
      {story1()}
      <img src="/story/second/3-1.png" alt="3-1 img" className="sec3-img" />
      <img src="/story/second/3-2.png" alt="3-2 img" className="sec3-img" />
      {inputs1.map((input, index) => (
        <form key={index} onSubmit={(e) => handleSubmit1(index, e)}>
          <input
            type="text"
            value={input}
            onChange={(e) => handleChange1(index, e.target.value)}
            disabled={isLocked1[index]}
            placeholder="ひらがなで入力"
            className="sec3-answer-input"
          />
          <button
            type="submit"
            disabled={isLocked1[index]}
            style={{ backgroundColor: isLocked1[index] ? "grey" : "lightblue" }}
            className="button"
          >
            送信
          </button>
        </form>
      ))}
      {second3State < 4 && <p>{wrongMessage}</p>}
      {second3State >= 4 && story2()}
      {second3State >= 4 &&
        inputs2.map((input, index) => (
          <form key={index} onSubmit={(e) => handleSubmit2(index, e)}>
            <input
              type="text"
              value={input}
              onChange={(e) => handleChange2(index, e.target.value)}
              disabled={isLocked2[index]}
              placeholder="ひらがなで入力"
              className="sec3-answer-input"
            />
            <button
              type="submit"
              disabled={isLocked2[index]}
              style={{
                backgroundColor: isLocked2[index] ? "grey" : "lightblue",
              }}
              className="button"
            >
              送信
            </button>
          </form>
        ))}
      {second3State >= 4 && second3State < 8 && <p>{wrongMessage}</p>}
      {second3State >= 8 && story3()}
      <br></br>
      <button onClick={toSecond} className="button">
        2ndに戻る
      </button>
      <br></br>
      {/* <button onClick={toHint} className='button'>ヒント</button> */}
      <br></br>
    </div>
  );
};

export default Second3;
