// src/components/StoryThirdPage.tsx
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./StoryThirdPage.css";
import { StoryStatusDict } from "../utils/DictionaryTypes";
import Cookies from "js-cookie";

const afterSecondStory = () => {
  return (
    <div>
      <br></br>
      <p>謎を解き、作曲家の人生を追体験した後</p>
      <br></br>
      <p>「なるほど、彼はこんな人生を歩んできたんだね………」</p>
      <p>
        「あれ？君達のカバンに入ってるそれ、なんだろう…？さっきまではそんな紙無かったような…」
      </p>
      <p>
        「これ、初めに私の元に届いた封筒と似てるみたい！謎を解いた事で新たな手がかりが手に入ったのかもしれないね、確認してみよう！」
      </p>
      <br></br>
      <p>
        「レコードを流せ……？そういえば、幻の音楽はレコードに入ってるって聞いた事があるような……？」
      </p>
      <br></br>
    </div>
  );
};

const lastText = () => {
  return (
    <div>
      <p>「なんだろう......この音楽は」</p>
      <p>
        聞いたことのない、けれども、どこか懐かしさを感じるような音楽だった。
      </p>
      <br></br>
      <p>音楽を聴きながら、妖精に出会ってからの出来事を思い出す。</p>
      <p>「私たち、色んな場所をめぐって音楽を聴いてきたね」</p>
      <p>「そうだね。大事なことも思い出せた」</p>
      <br></br>
      <p>流れる音楽に耳を傾けていた。</p>
      <p>ふと部屋の中を見ると、そこに妖精の姿はなかった。</p>
      <p>「あれ、どこに行ったんだろう」</p>
      <p>ずっと冒険を共にした仲間が突然消えて、動揺を隠せなかった。</p>
      <p>まだ、話していたかったのに。</p>
      <p>その時だった。</p>
      <br></br>
      <br></br>
      <br></br>
      <p>「私はここにいるよ」</p>
      <br></br>
      <br></br>
      <br></br>
      <p>目の前に現れたのは1人の人物だった。</p>
      <br></br>
      <p>「驚かせてごめんね。　私は作曲家の師匠なんだ。</p>
      <p>
        弟子が”幻の音楽”を作ったと聞いた矢先、寿命が来てしまって......。けど、どうしてもその音楽を聴きたくて、妖精として、あなたとそれを探すことにしたの」
      </p>
      <br></br>
      <p>「あなたは音楽をめぐるうちに、彼の人生を追っていたはず。</p>
      <p>
        楽しい記憶も、悲しい記憶も、生きている中で感じたものすべてを、音楽で表そうとしていたから。”幻の音楽”はそんな彼の集大成と言えるべき作品だった」
      </p>
      <br></br>
      <p>
        確かに、作曲家の作った音楽はどれも、過去の記憶を思い出すようなものばかりだった。
      </p>
      <br></br>
      <p>「彼の音楽を聴くことができて、本当によかった」</p>
      <p>彼女は嬉しそうに音楽に浸っている。</p>
      <p>しかし、その姿が少しずつ薄くなっていくように見えた。</p>
      <br></br>
      <p>「実は私が居られるのは、音楽が流れている間だけなんだ......</p>
      <p>音楽が止まってしまえば、幻と一緒に私も消えてしまうの。</p>
      <p>だから最後まで楽しんでね！」</p>
      <br></br>
      <p>『音楽はきっと、あなたの大切なものを思い出させてくれるはずだよ』</p>
      <br></br>
      <p>それじゃあね。</p>
      <br></br>
      <p>END</p>
      <br></br>
    </div>
  );
};

const StoryFirstPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const storyStatus: StoryStatusDict = location.state?.storyStatus || {
    currentStatus: 3,
    maxStatus: 3,
    map: -1,
    second1Status: 0,
    second2Status: 0,
    second3Status: 0,
    second4Status: 0,
    second5Status: 0,
    second6Status: 0,
    second7Status: 0,
    second8Status: 0,
    second9Status: 0,
    second10Status: 0,
    second11Status: 0,
    second12Status: 0,
    secondId: 0,
    thirdStatus: 0,
  };

  const [userAnswer, setUserAnswer] = useState(
    storyStatus["thirdStatus"] === 1 || storyStatus["maxStatus"] === 4
      ? "幻影"
      : ""
  );
  const [userLastAnswer, setUserLastAnswer] = useState(
    storyStatus["maxStatus"] === 4 ? "終幕" : ""
  );
  const [wrongMessage, setWrongMessage] = useState("");
  const [orinazoFlag, setOrinazoFlag] = useState(
    storyStatus["thirdStatus"] === 1 || storyStatus["maxStatus"] === 4
      ? true
      : false
  );
  const [lastTextFlag, setLastTextFlag] = useState(
    storyStatus["maxStatus"] === 4 ? true : false
  );

  const handleAnswerSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (userAnswer === "幻影") {
      setUserAnswer("");
      setWrongMessage("");
      setOrinazoFlag(true);
      storyStatus["thirdStatus"] = 1;
      Cookies.set("third_half_check", "true");
    } else {
      setUserAnswer("");
      setWrongMessage("キーワードが違うようです");
    }
  };

  const handleLastAnswer = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (userLastAnswer === "終幕") {
      setUserLastAnswer("");
      setWrongMessage("");
      setLastTextFlag(true);
    } else {
      setUserLastAnswer("");
      setWrongMessage("答えが違うようだ");
    }
  };

  const toClear = () => {
    storyStatus["thirdStatus"] = 1;
    storyStatus["maxStatus"] = 4;
    storyStatus["currentStatus"] = 4;
    navigate("/clear", { state: { storyStatus } });
  };

  const navigationToMap = (storyStatus: StoryStatusDict) => {
    if (storyStatus["map"] !== -1) {
      navigate("/map", { state: { storyStatus } });
    } else {
      navigate("/map/tmp", { state: { storyStatus } });
    }
  };

  const toSecond = () => {
    storyStatus["currentStatus"] = 2;
    navigate("/story/second", { state: { storyStatus } });
  };

  const toHint = () => {
    window.open(
      "https://anothervision.tokyo/special/rechord-web/rechord-hint.html",
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <div className="third-page">
      <br></br>
      <button onClick={() => navigationToMap(storyStatus)} className="button">
        地図
      </button>
      <h1 className="text">Last Step</h1>
      {afterSecondStory()}
      <br></br>
      <div className="padded">
        <h3 className="text">room3の近くにあるつぼの隣を開けろ</h3>
      </div>
      <h2 className="text">キーワード入力はこちら</h2>
      <form onSubmit={handleAnswerSubmit}>
        <input
          type="text"
          value={userAnswer}
          onChange={(e) => setUserAnswer(e.target.value)}
          disabled={storyStatus["thirdStatus"] === 1}
          className="answer-input"
        />
        <button
          type="submit"
          disabled={storyStatus["thirdStatus"] === 1}
          className="button"
        >
          送信
        </button>
      </form>
      {!orinazoFlag && <p>{wrongMessage}</p>}
      {orinazoFlag && (
        <div>
          <img
            src="/story/howtofold.png"
            alt="howtohold"
            className="last-img"
          />

          <p>「幻の音楽がある部屋が分かったら実際にそこに行ってみて」</p>
          <p>--------------------</p>
          <p>幻の音楽を聴いた後、入手したキーワードを入力して下さい。</p>
          <form onSubmit={handleLastAnswer}>
            <input
              type="text"
              value={userLastAnswer}
              onChange={(e) => setUserLastAnswer(e.target.value)}
              disabled={lastTextFlag}
              className="answer-input"
            />
            <button type="submit" disabled={lastTextFlag} className="button">
              送信
            </button>
          </form>
          <p>{wrongMessage}</p>
        </div>
      )}
      {lastTextFlag && (
        <div>
          {lastText()}
          <button onClick={toClear} className="button">
            クリアページへ
          </button>
        </div>
      )}

      <button onClick={toSecond} className="button">
        2ndに戻る
      </button>
      <br></br>
      {/* <button onClick={toHint} className='button'>ヒント</button> */}
      <br></br>
    </div>
  );
};

export default StoryFirstPage;
