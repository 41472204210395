// src/Story/Second4.tsx
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./Second4.css";
import { StoryStatusDict } from "../../utils/DictionaryTypes";
import Cookies from "js-cookie";

const clearedStory = () => {
  return (
    <div>
      <p>ぎこちないピアノの音。練習に打ち込む小学生の私。</p>
      <br></br>
      <p>師匠のおかげで、ピアノを弾くことが楽しくなった。</p>
      <p>コンクールにもたくさん挑戦した。</p>
      <p>放課後に友達と遊べなくても苦ではなかった。</p>
      <br></br>
      <p>音楽祭や卒業式では伴奏を務めた。</p>
      <p>
        選ばれたときはもちろん嬉しかったし、同級生に褒めてもらえたときのことは今でも覚えてる。
      </p>
      <br></br>
      <p>しかし、中学生になって周りとの差を感じはじめた。</p>
      <br></br>
      <p>
        どれだけ練習に時間を費やしても、何度コンクールに挑戦しても、良い結果は得られなかった。
      </p>
      <p>周りにどんどん追い抜かされていった。</p>
      <p>何のためにピアノを弾いているのか、わからなくなってしまった。</p>
      <br></br>
      <p>そんな時だった。</p>
      <p>師匠が「作曲」という、音楽との新たな関わり方を教えてくれたのは。</p>
      <br></br>
    </div>
  );
};

const Second4: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const storyStatus: StoryStatusDict = location.state?.storyStatus || {
    currentStatus: 2,
    maxStatus: 2,
    second1Status: 0,
    second2Status: 0,
    second3Status: 0,
    second4Status: 0,
    second5Status: 0,
    second6Status: 0,
    second7Status: 0,
    second8Status: 0,
    second9Status: 0,
    second10Status: 0,
    second11Status: 0,
    second12Status: 0,
    secondId: 0,
  };

  const [userAnswer, setUserAnswer] = useState("");
  const [second4State, setSecond4State] = useState(
    storyStatus["second4Status"]
  );
  const [flagStep1, setFlagStep1] = useState(true);
  const [flagStep2, setFlagStep2] = useState(false);
  const [flagClear1, setFlagClear1] = useState(false);
  const [flagClear2, setFlagClear2] = useState(
    storyStatus["second4Status"] === 10 ? true : false
  );

  const imgSrc1st = "/story/second/4-step1.png";
  const imgSrcLast = "/story/second/4-last.png";
  const imgSrcWrong = "/story/second/4-wrong.png";
  const imgSrcSapphire = "/story/second/4-sapphire2.png";
  const firstAnswerList: string[] = [
    "りーだーしっぷ",
    "いちがんれふ",
    "めざましどけい",
    "ざらめ",
    "かいそう",
    "さふぁいあ",
    "せみ",
  ];
  const [digital, setDigital] = useState<boolean[]>([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [flagWrong, setFlagWrong] = useState<boolean>(false);
  const [flagMtFuji, setFlagMtFuji] = useState<boolean>(false);
  const [flagSapphire, setFlagSapphire] = useState<boolean>(false);

  const handleAnswerSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (flagStep1) {
      if (
        (userAnswer === "富士山" || userAnswer === "ふじさん") &&
        flagMtFuji &&
        !digital[0]
      ) {
        setFlagWrong(false);
        setFlagMtFuji(false);
        setFlagSapphire(true);
      } else if (!firstAnswerList.includes(userAnswer)) {
        setFlagWrong(true);
        setFlagSapphire(false);
        if (
          (userAnswer === "富士山" || userAnswer === "ふじさん") &&
          !digital[0]
        ) {
          setFlagMtFuji(true);
        }
      } else {
        setFlagWrong(false);
        setFlagSapphire(false);
        const updatedDigital = [...digital];
        if (userAnswer === "さふぁいあ") {
          if (updatedDigital[0] === false) {
            updatedDigital[0] = true;
            alert("上の画像に変化が起きた。");
          } else {
            alert("その答えは既に送られているようです。");
          }
        } else if (userAnswer === "いちがんれふ") {
          if (updatedDigital[1] === false) {
            updatedDigital[1] = true;
            alert("上の画像に変化が起きた。");
          } else {
            alert("その答えは既に送られているようです。");
          }
        } else if (userAnswer === "かいそう") {
          if (updatedDigital[2] === false) {
            updatedDigital[2] = true;
            alert("上の画像に変化が起きた。");
          } else {
            alert("その答えは既に送られているようです。");
          }
        } else if (userAnswer === "りーだーしっぷ") {
          if (updatedDigital[3] === false) {
            updatedDigital[3] = true;
            alert("上の画像に変化が起きた。");
          } else {
            alert("その答えは既に送られているようです。");
          }
        } else if (userAnswer === "めざましどけい") {
          if (updatedDigital[4] === false) {
            updatedDigital[4] = true;
            alert("上の画像に変化が起きた。");
          } else {
            alert("その答えは既に送られているようです。");
          }
        } else if (userAnswer === "ざらめ") {
          if (updatedDigital[5] === false) {
            updatedDigital[5] = true;
            alert("上の画像に変化が起きた。");
          } else {
            alert("その答えは既に送られているようです。");
          }
        } else if (userAnswer === "せみ") {
          if (updatedDigital[6] === false) {
            updatedDigital[6] = true;
            alert("上の画像に変化が起きた。");
          } else {
            alert("その答えは既に送られているようです。");
          }
        }
        setDigital(updatedDigital);
      }
      setUserAnswer("");
    } else if (flagClear1 && flagStep2) {
      if (userAnswer === "しんせさいざー") {
        setFlagClear2(true);
        storyStatus["second4Status"] = 10;
        Cookies.set("clear_rechord_second_4", "true", { expires: 36500 });
      } else {
        setFlagWrong(true);
      }
    }
  };

  const handleClosePopup = () => {
    setFlagSapphire(false);
    setFlagWrong(false);
  };

  useEffect(() => {
    if (!digital.includes(false)) {
      setFlagClear1(true);
      setSecond4State(5);
    }
    if (second4State >= 5) {
      setDigital([true, true, true, true, true, true, true]);
    }
  }, [digital, second4State]);

  const toStep2 = () => {
    setFlagStep1(false);
    setFlagStep2(true);
  };

  const toStep1 = () => {
    setFlagStep1(true);
    setFlagStep2(false);
  };

  const toSecond = () => {
    storyStatus["currentStatus"] = 2;
    if (second4State === 10) {
      storyStatus["second4Status"] = 10;
      Cookies.set("clear_rechord_second_4", "true", { expires: 36500 });
    }
    navigate("/story/second", { state: { storyStatus } });
  };

  const toHint = () => {
    window.open(
      "https://anothervision.tokyo/special/rechord-web/rechord-hint.html",
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <div className="sec4-container">
      <br></br>
      <h2 className="text">2nd 青年期の記憶3</h2>
      <div>※この謎は、一部検索を必要とする場面がございます。</div>
      {flagStep1 && (
        <div className="sec4-main-field">
          <img src={imgSrc1st} alt="1st step" className="sec4-1st" />
          {digital[0] && <div className="red-rect" id="rect1A"></div>}
          {digital[1] && <div className="red-rect" id="rect1B"></div>}
          {digital[2] && <div className="red-rect" id="rect2A"></div>}
          {digital[3] && <div className="red-rect" id="rect2B"></div>}
          {digital[4] && <div className="red-rect" id="rect3B"></div>}
          {digital[5] && <div className="red-rect" id="rect2C"></div>}
          {digital[6] && <div className="red-rect" id="rect3C"></div>}
        </div>
      )}
      <br></br>
      {flagWrong && !flagSapphire && (
        <div
          onClick={handleClosePopup}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <div className="sec4-popup-container">
            <img src={imgSrcWrong} alt="Wrong" className="sec4-image" />
          </div>
        </div>
      )}
      {flagSapphire && (
        <div
          onClick={handleClosePopup}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <div className="sec4-popup-container">
            <img src={imgSrcSapphire} alt="Sapphire" className="sec4-image" />
          </div>
        </div>
      )}
      {flagStep1 && (
        <div>
          <img src="/story/second/4-Q1.png" alt="Q1" className="sec4-Q123" />
          <img src="/story/second/4-Q2.png" alt="Q2" className="sec4-Q123" />
          <img src="/story/second/4-Q3.png" alt="Q3" className="sec4-Q123" />
        </div>
      )}

      {flagStep2 && (
        <img src={imgSrcLast} alt="Last Step" className="sec4-image" />
      )}

      <form onSubmit={handleAnswerSubmit} className="sec4-form">
        <input
          type="text"
          value={userAnswer}
          onChange={(e) => setUserAnswer(e.target.value)}
          className="sec4-answer-input"
          placeholder="ひらがなで入力"
          disabled={flagClear2}
        />
        <button
          type="submit"
          disabled={flagClear2}
          className="sec4-submit-button"
        >
          送信
        </button>
      </form>
      {flagClear2 && flagStep2 && clearedStory()}
      <br></br>
      {flagStep1 && flagClear1 && (
        <button onClick={toStep2} className="sec4-submit-button">
          Last Stepを表示
        </button>
      )}
      {flagStep2 && !flagClear2 && (
        <button onClick={toStep1} className="sec4-submit-button">
          Step1を表示
        </button>
      )}
      <button onClick={toSecond} className="sec4-submit-button">
        2ndに戻る
      </button>
      <br></br>
      {/* <button onClick={toHint} className='button'>ヒント</button> */}
      <br></br>
    </div>
  );
};

export default Second4;
