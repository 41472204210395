// src/components/StorySecondTmpPage.tsx
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./StorySecondTmpPage.css";
import { StoryStatusDict } from "../utils/DictionaryTypes";
import Cookies from "js-cookie";

const StorySecondTmpPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const storyStatus: StoryStatusDict = location.state?.storyStatus || {
    currentStatus: 2,
    maxStatus: 2,
    map: -1,
    second1Status: 0,
    second2Status: 0,
    second3Status: 0,
    second4Status: 0,
    second5Status: 0,
    second6Status: 0,
    second7Status: 0,
    second8Status: 0,
    second9Status: 0,
    second10Status: 0,
    second11Status: 0,
    second12Status: 0,
    secondId: 0,
    thirdStatus: 0,
  };

  const nextId: number = storyStatus["secondId"];
  const [userAnswer, setUserAnswer] = useState("");
  const [wrongMessage, setWrongMessage] = useState("");
  const posList = [
    "♢",
    "room12",
    "room13",
    "特別ホール",
    "room5",
    "room11",
    "room1",
    "room6~9",
    "room15",
    "room17",
    "room4",
    "room14",
    "room3",
  ];
  const keywordList = [
    "dummy",
    "メモリー",
    "ホワイト",
    "サーチ",
    "破片",
    "勇者",
    "宇宙",
    "一服",
    "カード",
    "ビンゴ",
    "ボックス",
    "頂点",
    "サイクル",
  ];

  const handleAnswerSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (userAnswer === keywordList[nextId]) {
      storyStatus["secondId"] = 0;
      navigate(`/story/second/${nextId}`, { state: { storyStatus } });
    } else {
      setWrongMessage("キーワードが違うようです");
    }
  };

  const backToSecond = () => {
    storyStatus["currentStatus"] = 2;
    storyStatus["secondId"] = 0;
    navigate("/story/second", { state: { storyStatus } });
  };

  return (
    <div className="second-container">
      <br></br>
      <h3 className="text">「{posList[nextId]}」の前へ向かい、</h3>
      <h3 className="text">キーワードを入力せよ</h3>
      <form onSubmit={handleAnswerSubmit} className="second-form">
        <input
          type="text"
          value={userAnswer}
          onChange={(e) => setUserAnswer(e.target.value)}
          className="second-answer-input"
        />
        <button type="submit" className="second-submit-button">
          送信
        </button>
      </form>
      <p>{wrongMessage}</p>
      <button onClick={backToSecond} className="button">
        ステージ選択画面に戻る
      </button>
    </div>
  );
};

export default StorySecondTmpPage;
