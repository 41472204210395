// src/components/MapPage.tsx
import React, {
  useState,
  useEffect,
  useRef,
  ReactNode,
  CSSProperties,
} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./MapPage.css";
import { StoryStatusDict } from "../utils/DictionaryTypes";
import Cookies from "js-cookie";

const pos = (w: number, h: number, t: number, l: number) => {
  const style: CSSProperties = {
    width: w + "%",
    height: h + "%",
    top: t + "%",
    left: l + "%",
  };
  return style;
};

const MapPage: React.FC = () => {
  // ステータスの取得
  const navigate = useNavigate();
  const location = useLocation();
  const storyStatus: StoryStatusDict = location.state?.storyStatus || {
    currentStatus: 0,
    maxStatus: 0,
    map: -1,
    second1Status: 0,
    second2Status: 0,
    second3Status: 0,
    second4Status: 0,
    second5Status: 0,
    second6Status: 0,
    second7Status: 0,
    second8Status: 0,
    second9Status: 0,
    second10Status: 0,
    second11Status: 0,
    second12Status: 0,
    secondId: 0,
    thirdStatus: 0,
  };

  // ポップアップの表示
  const [imgSrc, setImgSrc] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState<ReactNode>("");
  const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });
  // レコードの回転
  const [isRotating, setIsRotating] = useState(false);
  const rotationRef = useRef<number>(0);
  const rotateIntervalRef = useRef<NodeJS.Timeout | null>(null);

  // 音楽ファイルのリファレンス
  const musicRechordRef = useRef(new Audio("/music/レコリコ.mp3"));
  const musicJudgementRef = useRef(new Audio("/music/ジャッジ.mp3"));
  const musicAnaBingoRef = useRef(new Audio("/music/アナビンゴ.mp3"));
  const musicItetenRef = useRef(new Audio("/music/イテテン.mp3"));
  const musicTensenRef = useRef(new Audio("/music/テンセン.mp3"));
  const musicThiefRef = useRef(new Audio("/music/ぬすってすと.mp3"));
  const musicMusubareRef = useRef(new Audio("/music/むすばれ.mp3"));
  const musicRecycleRef = useRef(new Audio("/music/リサイコロ.mp3"));
  const musicWansakaRef = useRef(new Audio("/music/ワンサカ.mp3"));
  const musicRoom2Ref = useRef(new Audio("/music/抽選中.mp3"));
  const musicZenninRef = useRef(new Audio("/music/ゼンニン.mp3"));
  const musicHietraRef = useRef(new Audio("/music/ヒエトラ.mp3"));
  const musicKissaRef = useRef(new Audio("/music/喫茶.mp3"));

  useEffect(() => {
    // 音楽が終わったらループするように設定
    const musicRechord = musicRechordRef.current;
    const musicJudgement = musicJudgementRef.current;
    const musicAnaBingo = musicAnaBingoRef.current;
    const musicIteten = musicItetenRef.current;
    const musicMusubare = musicMusubareRef.current;
    const musicRecycle = musicRecycleRef.current;
    const musicRoom2 = musicRoom2Ref.current;
    const musicTensen = musicTensenRef.current;
    const musicThief = musicThiefRef.current;
    const musicWansaka = musicWansakaRef.current;
    const musicHietra = musicHietraRef.current;
    const musicZennin = musicZenninRef.current;
    const musicKissa = musicKissaRef.current;

    const handleEnded = (music: HTMLAudioElement) => {
      music.currentTime = 0;
      music.play();
    };
    const handleEnded_rechord = () => handleEnded(musicRechord);
    const handleEnded_judgement = () => handleEnded(musicJudgement);
    const handleEnded_anabingo = () => handleEnded(musicAnaBingo);
    const handleEnded_musubare = () => handleEnded(musicMusubare);
    const handleEnded_iteten = () => handleEnded(musicIteten);
    const handleEnded_recycle = () => handleEnded(musicRecycle);
    const handleEnded_rooms2 = () => handleEnded(musicRoom2);
    const handleEnded_tensen = () => handleEnded(musicTensen);
    const handleEnded_thief = () => handleEnded(musicThief);
    const handleEnded_wansaka = () => handleEnded(musicWansaka);
    const handleEnded_hietora = () => handleEnded(musicHietra);
    const handleEnded_zennin = () => handleEnded(musicZennin);
    const handleEnded_Kissa = () => handleEnded(musicKissa);
    musicRechord.addEventListener("ended", handleEnded_rechord);
    musicJudgement.addEventListener("ended", handleEnded_judgement);
    musicAnaBingo.addEventListener("ended", handleEnded_anabingo);
    musicMusubare.addEventListener("ended", handleEnded_musubare);
    musicIteten.addEventListener("ended", handleEnded_iteten);
    musicRecycle.addEventListener("ended", handleEnded_recycle);
    musicRoom2.addEventListener("ended", handleEnded_rooms2);
    musicTensen.addEventListener("ended", handleEnded_tensen);
    musicThief.addEventListener("ended", handleEnded_thief);
    musicWansaka.addEventListener("ended", handleEnded_wansaka);
    musicHietra.addEventListener("ended", handleEnded_hietora);
    musicZennin.addEventListener("ended", handleEnded_zennin);
    musicKissa.addEventListener("ended", handleEnded_Kissa);

    return () => {
      musicRechord.removeEventListener("ended", handleEnded_rechord);
      musicJudgement.removeEventListener("ended", handleEnded_judgement);
      musicAnaBingo.removeEventListener("ended", handleEnded_anabingo);
      musicMusubare.removeEventListener("ended", handleEnded_musubare);
      musicIteten.removeEventListener("ended", handleEnded_iteten);
      musicRecycle.removeEventListener("ended", handleEnded_recycle);
      musicRoom2.removeEventListener("ended", handleEnded_rooms2);
      musicTensen.removeEventListener("ended", handleEnded_tensen);
      musicWansaka.removeEventListener("ended", handleEnded_wansaka);
      musicHietra.removeEventListener("ended", handleEnded_hietora);
      musicZennin.removeEventListener("ended", handleEnded_zennin);
      musicKissa.removeEventListener("ended", handleEnded_Kissa);
    };
  }, []);

  const navigationToStory = (storyStatus: StoryStatusDict) => {
    Cookies.set("map_browse", "true");
    switch (storyStatus["currentStatus"]) {
      case 0:
        navigate("/", { state: { storyStatus } });
        break;
      case 1:
        navigate("/story/first", { state: { storyStatus } });
        break;
      case 2:
        navigate("/story/second", { state: { storyStatus } });
        break;
      case 3:
        navigate("/story/third", { state: { storyStatus } });
        break;
      default:
        break;
    }
  };

  const handleMapClick = (key: string) => {
    // ポップアップが既に表示されている場合は何もしない
    if (showPopup) return;

    let newImgSrc = "";
    if (key === "Record") {
      newImgSrc = "/popup/POP_Rechord.png";
    } else if (key === "Judge") {
      newImgSrc = "/popup/POP_ジャッジメント.png";
    } else if (key === "Room10") {
      newImgSrc = "/popup/POP_room10.png";
    } else if (key === "Wansaka") {
      newImgSrc = "/popup/POP_ワンサカード.png";
    } else if (key === "Musubare") {
      newImgSrc = "/popup/POP_結ばれない君と.png";
    } else if (key === "Iteten") {
      newImgSrc = "/popup/POP_カイテテンセイ.png"; // 一般C
    } else if (key === "Nusutto") {
      newImgSrc = "/popup/POP_ぬすってすと！.png"; // 新規X
    } else if (key === "Bingo") {
      newImgSrc = "/popup/POP_アケロナイスビンゴ.png"; // 新規Y
    } else if (key === "Tensen") {
      newImgSrc = "/popup/POP_転遷の部屋と探求の旅人.png";
    } else if (key === "Hietora") {
      newImgSrc = "/popup/POP_ヒエトラ.png";
    } else if (key === "Zennin") {
      newImgSrc = "/popup/POP_善人開発計画.png";
    } else if (key === "Replus") {
      newImgSrc = "/popup/POP_リサイコロプラス.png"; //リサイコロ2
    } else if (key === "Cafe") {
      newImgSrc = "/popup/POP_なぞと喫茶.png"; // カフェ謎
    } else if (key === "Room2") {
      newImgSrc = "/popup/POP_room2.png"; // スタッフルーム
    }

    // for debug
    // let content = `選択された場所の情報 x: ${x_relative}, y: ${y_relative}`;
    // console.log(content);

    // imgSrcが空でない場合のみポップアップを表示
    if (newImgSrc !== "") {
      setImgSrc(newImgSrc);
      setPopupContent(
        <img src={newImgSrc} alt="Popup" className="popup-image" />
      );
      // setPopupPosition({ top: rect.height / 2, left: rect.width / 2 });
      setShowPopup(true);
    }
  };

  const toggleMusicAndRotation = () => {
    setIsRotating(!isRotating);
    if (!isRotating) {
      // Start rotation and music
      if (rotateIntervalRef.current === null) {
        rotateIntervalRef.current = setInterval(() => {
          rotationRef.current += 10;
          (
            document.querySelector(".record-image") as HTMLElement
          ).style.transform = `rotate(${rotationRef.current}deg)`;
        }, 50);
      }

      try {
        if (imgSrc === "/popup/POP_Rechord.png") {
          musicRechordRef.current.play();
        } else if (imgSrc === "/popup/POP_ジャッジメント.png") {
          musicJudgementRef.current.play();
        } else if (imgSrc === "/popup/POP_ワンサカード.png") {
          musicWansakaRef.current.play();
        } else if (imgSrc === "/popup/POP_結ばれない君と.png") {
          musicMusubareRef.current.play();
        } else if (imgSrc === "/popup/POP_カイテテンセイ.png") {
          musicItetenRef.current.play();
        } else if (imgSrc === "/popup/POP_ぬすってすと！.png") {
          musicThiefRef.current.play();
        } else if (imgSrc === "/popup/POP_アケロナイスビンゴ.png") {
          musicAnaBingoRef.current.play();
        } else if (imgSrc === "/popup/POP_転遷の部屋と探求の旅人.png") {
          musicTensenRef.current.play();
        } else if (imgSrc === "/popup/POP_room2.png") {
          musicRoom2Ref.current.play();
        } else if (imgSrc === "/popup/POP_リサイコロプラス.png") {
          musicRecycleRef.current.play();
        } else if (imgSrc === "/popup/POP_ヒエトラ.png") {
          musicHietraRef.current.play();
        } else if (imgSrc === "/popup/POP_善人開発計画.png") {
          musicZenninRef.current.play();
        } else if (imgSrc === "/popup/POP_なぞと喫茶.png") {
          musicKissaRef.current.play();
        }
      } catch (error) {
        console.error("Audio play error:", error);
      }
    } else {
      // Stop rotation and music
      if (rotateIntervalRef.current !== null) {
        clearInterval(rotateIntervalRef.current);
        rotateIntervalRef.current = null;
      }

      musicRechordRef.current.pause();
      musicJudgementRef.current.pause();
      musicAnaBingoRef.current.pause();
      musicItetenRef.current.pause();
      musicMusubareRef.current.pause();
      musicRecycleRef.current.pause();
      musicRoom2Ref.current.pause();
      musicTensenRef.current.pause();
      musicThiefRef.current.pause();
      musicWansakaRef.current.pause();
      musicHietraRef.current.pause();
      musicZenninRef.current.pause();
      musicKissaRef.current.pause();
    }
  };

  const handleClosePopup = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation(); // イベントの伝播を停止
    setShowPopup(false);
    musicRechordRef.current.pause();
    musicJudgementRef.current.pause();
    musicAnaBingoRef.current.pause();
    musicItetenRef.current.pause();
    musicMusubareRef.current.pause();
    musicRecycleRef.current.pause();
    musicRoom2Ref.current.pause();
    musicTensenRef.current.pause();
    musicThiefRef.current.pause();
    musicWansakaRef.current.pause();
    musicHietraRef.current.pause();
    musicZenninRef.current.pause();
    musicKissaRef.current.pause();

    setIsRotating(false);
    if (rotateIntervalRef.current !== null) {
      clearInterval(rotateIntervalRef.current);
      rotateIntervalRef.current = null;
    }
  };

  const toMapTmp = (storyStatus: StoryStatusDict) => {
    storyStatus["map"] = -1;
    navigate("/map/tmp", { state: { storyStatus } });
  };

  return (
    <div className="map-container">
      <br></br>
      <button
        onClick={() => navigationToStory(storyStatus)}
        className="back-button"
      >
        ストーリー
      </button>
      <button onClick={() => toMapTmp(storyStatus)} className="back-button">
        地図の見方
      </button>

      <div>
        <p style={{ fontWeight: "bold", textAlign: "center" }}>
          ※画像・音楽の読み込みに
          <br />
          時間がかかる場合がございます。
        </p>
      </div>

      <div style={{ position: "relative" }}>
        {/* マップ本体 */}
        <img
          src="/Rechord_map.png"
          alt="Background Map"
          className="background-image"
        />

        {/* クリック可能要素 */}
        {/* メインエリア */}
        <MapClickCountent
          style={pos(23, 15, 15, 57)}
          onClick={() => handleMapClick("Judge")}
        />
        <MapClickCountent
          style={pos(27.5, 5.5, 41.5, 29)}
          onClick={() => handleMapClick("Cafe")}
        />
        <MapClickCountent
          style={pos(11.5, 5.5, 46.5, 14)}
          onClick={() => handleMapClick("Bingo")}
        />

        <MapClickCountent
          style={pos(14, 5.5, 46.5, 29)}
          onClick={() => handleMapClick("Musubare")}
        />
        <MapClickCountent
          style={pos(14, 5.5, 46.5, 42.5)}
          onClick={() => handleMapClick("Iteten")}
        />

        <MapClickCountent
          style={pos(6.5, 3.5, 52, 36.5)}
          onClick={() => handleMapClick("Record")}
        />
        <MapClickCountent
          style={pos(6.5, 3.5, 52, 42.5)}
          onClick={() => handleMapClick("Room2")}
        />
        <MapClickCountent
          style={pos(8, 3.5, 52, 48.5)}
          onClick={() => handleMapClick("Replus")}
        />

        {/* サブエリア */}
        <MapClickCountent
          style={pos(6, 3.5, 39.5, 64.5)}
          onClick={() => handleMapClick("Room10")}
        />
        <MapClickCountent
          style={pos(5.5, 5.6, 39.5, 70.5)}
          onClick={() => handleMapClick("Tensen")}
        />
        <MapClickCountent
          style={pos(5.6, 6, 39.5, 76)}
          onClick={() => handleMapClick("Nusutto")}
        />
        <MapClickCountent
          style={pos(5.7, 6.6, 39.5, 81.5)}
          onClick={() => handleMapClick("Zennin")}
        />

        <MapClickCountent
          style={pos(14.5, 4.6, 47, 75.5)}
          onClick={() => handleMapClick("Hietora")}
        />
        <MapClickCountent
          style={pos(17.5, 5.6, 51.5, 72.5)}
          onClick={() => handleMapClick("Wansaka")}
        />
      </div>

      {showPopup && (
        <div className="popup-container-outer">
          <div className="popup-container">
            {popupContent}
            {imgSrc !== "/popup/POP_room10.png" && (
              <img
                src="/other_material/record_small.png"
                alt="Record"
                className={`record-image ${isRotating ? "rotating" : ""}`}
                onClick={toggleMusicAndRotation}
                style={{ transform: `rotate(${rotationRef.current}deg)` }}
              />
            )}
            <button className="close-button" onClick={handleClosePopup}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                height="100%"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#000000"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default MapPage;

type Props = {
  style: CSSProperties;
  onClick: React.MouseEventHandler<HTMLDivElement>;
};
const MapClickCountent = ({ style, onClick }: Props) => {
  return <div className="map_select_btn" style={style} onClick={onClick} />;
};
