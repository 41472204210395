// src/Story/Second10.tsx
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Second10.css'
import { StoryStatusDict } from '../../utils/DictionaryTypes';
import Cookies from 'js-cookie';

const clearedStory = () => {
    return (
        <div>
            <p>五時を知らせるチャイムが聞こえてくる。私は部屋のソファに横になっていた。</p>
            <p>幼い頃から十年近く続けてきたピアノを無断で休んだのは、この日が初めてだった。</p>
            <br></br>
            <p>先週末のコンクールでの演奏を思い出すたび、胸が苦しくなる。</p>
            <br></br>
            <p>無数の目線に全身を刺される感覚。頭の中に響く不協和音。曖昧になる指先。</p>
            <br></br>
            <p>何もかも捨てたくなった。あの時の記憶も、自分の弱さも、中途半端な才能も。</p>
            <p>いっそ、自分の中の「音楽」全てを捨ててしまえば＿＿＿</p>
            <br></br><br></br><br></br><br></br><br></br>
            <p>突然、電話がかかってきた。</p>
            <br></br><br></br><br></br><br></br><br></br>
            <p>「もしもし？今日、お休み？もしかして、体調悪い？」</p>
            <br></br>
            <p>師匠からだった。私が言葉に詰まっていると、</p>
            <br></br>
            <p>「私は君のこと、否定しないよ。思ってること全部聞かせて。」</p>
            <br></br>
            <p>何もかも、わかっているようだった。</p>
            <p>抱え込んだ想いを、全部師匠にぶつけた。</p>
            <br></br>
            <p>「これからどうする？ピアノやめる？音楽はまだ好き？」</p>
            <br></br>
            <p>音楽はやっぱり好きだ。でも、ピアノに向き合う勇気はまだなかった。</p>
            <br></br>
            <p>「そうだよね…。それなら、作曲とかやってみる？」</p>
            <br></br>
            <p>予想外の提案だった。</p>
            <br></br>
            <p>「きっと、君はとっても感受性が豊かなんだよ。コンクールの時もそう。あとはそれを音楽にできるかってとこだけど。まあ、きっとできるよ、君なら。私もちょっとは経験あるから、面倒みてあげるからさ、好きにやりな。」</p>
            <br></br>
            <p>「君ならできる」と言われて安心したのは初めてだった。</p>
            <p>師匠は私が捨てたかったものを、大事な武器にしてくれた。</p>
            <p>私が作曲家になる運命は、あの日決まったのだ。</p>
            <br></br>
        </div>
    );
}

const Second10: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const storyStatus: StoryStatusDict = location.state?.storyStatus || {
        currentStatus: 2,
        maxStatus: 2,
        second1Status: 0,
        second2Status: 0,
        second3Status: 0,
        second4Status: 0,
        second5Status: 0,
        second6Status: 0,
        second7Status: 0,
        second8Status: 0,
        second9Status: 0,
        second10Status: 0,
        second11Status: 0,
        second12Status: 0,
        secondId: 0,
    };

    const [second10State, setSecond10State] = useState<number>(storyStatus['second10Status']);
    const [wrongMessage, setWrongMessage] = useState<string>('');
    
    const keywordsPhase1 = ['かーる', 'あんぷ', 'じゃず', 'いせい', 'にだい', 'こえだ', 'おかし', 'こーち', 'まにあ'];

    const [step21flag, setStep21Flag] = useState<boolean>(false);
    const [step22flag, setStep22Flag] = useState<boolean>(false);
    const [step23flag, setStep23Flag] = useState<boolean>(false);

    const [inputs1, setInputs1] = useState<string[]>(['', '', '', '', '', '', '', '', '']);
    const [isLocked1, setIsLocked1] = useState<boolean[]>([false, false, false, false, false, false, false, false, false]);
    const [answeredKeywords1, setAnsweredKeyword1] = useState<string[]>([]); 
    const [userAnswer21, setUserAnswer21] = useState('');
    const [userAnswer22, setUserAnswer22] = useState('');
    const [userAnswer23, setUserAnswer23] = useState('');
    const [userAnswerLast, setUserAnswerLast] = useState('');

    const handleSubmit1 = (index: number, e: React.FormEvent) => {
        e.preventDefault();
        const input = inputs1[index].trim();

        if (answeredKeywords1.includes(input)) {
            setWrongMessage('その答えは既に送られているようです');
            return;
        }

        if (keywordsPhase1.includes(input)) {
            setWrongMessage('');
            setAnsweredKeyword1([...answeredKeywords1, input]);
            const updatedIsLocked = [...isLocked1];
            updatedIsLocked[index] = true;
            setIsLocked1(updatedIsLocked);
        } else {
            setWrongMessage('答えが正しくないようです')
        }
    };

    const handleChange1 = (index: number, value: string) => {
        const updatedInputs = [...inputs1];
        updatedInputs[index] = value;
        setInputs1(updatedInputs);
    }

    const handleSubmit21 = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (userAnswer21 === 'じゅぜっぺ') {
            setStep21Flag(true);
            setWrongMessage('');
        } else {
            setWrongMessage('答えが正しくないようです');
        }
    }

    const handleSubmit22 = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (userAnswer22 === 'るーまにあ') {
            setStep22Flag(true);
            setWrongMessage('');
        } else {
            setWrongMessage('答えが正しくないようです');
        }
    }

    const handleSubmit23 = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (userAnswer23 === 'おにたいじ') {
            setStep23Flag(true);
            setWrongMessage('');
        } else {
            setWrongMessage('答えが正しくないようです');
        }
    }

    const handleSubmitLast = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (userAnswerLast === 'あるぺじお') {
            setSecond10State(10);
            setWrongMessage('');
            Cookies.set('clear_rechord_second_10', "true", { expires:7 });
        } else {
            setWrongMessage('答えが正しくないようです');
        }
    }

    const toSecond = () => {
        storyStatus['currentStatus'] = 2;
        storyStatus['second10Status'] = second10State;
        navigate('/story/second', { state: { storyStatus } });
    }

    useEffect(() => {
        if (second10State >= 1) {
            setIsLocked1([true, true, true, true, true, true, true, true, true]);
            if (second10State >= 2) {
                setStep21Flag(true);
                setStep22Flag(true);
                setStep23Flag(true);
            }
        }
    }, [second10State]);

    useEffect (() => {
        if (!isLocked1.includes(false) && second10State === 0) {
            setSecond10State(1);
        } 
    }, [isLocked1, second10State])

    useEffect (() => {
        if (step21flag && step22flag && step23flag && second10State === 1) {
            setSecond10State(2);
        }
    }, [second10State, step21flag, step22flag, step23flag])

    const toHint = () => {
        window.open('https://anothervision.tokyo/special/rechord-web/rechord-hint.html', '_blank', 'noopener,noreferrer');
    };

    return (
        <div className='sec10-container'>
            <br></br>
            <h2 className='text'>2nd 青年期の記憶2</h2>
            <h3 className='text'>掲示物を見て謎を解こう</h3>
            <p>※解く順番の指定はありません</p>
            <div className='img-container-33'>
                <img src='/story/second/10-1.png' alt='10-1' className='img'/>
                <img src='/story/second/10-2.png' alt='10-2' className='img'/>
                <img src='/story/second/10-3.png' alt='10-3' className='img'/>
                <img src='/story/second/10-4.png' alt='10-4' className='img'/>
                <img src='/story/second/10-5.png' alt='10-5' className='img'/>
                <img src='/story/second/10-6.png' alt='10-6' className='img'/>
                <img src='/story/second/10-7.png' alt='10-7' className='img'/>
                <img src='/story/second/10-8.png' alt='10-8' className='img'/>
                <img src='/story/second/10-9.png' alt='10-9' className='img'/>
            </div>
            <br></br>
            {inputs1.map((input, index) => (
                <form key={index} onSubmit={(e) => handleSubmit1(index, e)} >
                    <input
                        type="text"
                        value={input}
                        onChange={(e) =>handleChange1(index, e.target.value)}
                        disabled={isLocked1[index]}
                        placeholder='ひらがなで入力'
                        className='sec10-answer-input'
                    />
                    <button type="submit" disabled={isLocked1[index]} style={{ backgroundColor: isLocked1[index] ? 'grey' : 'lightblue' }} className='button'>
                        送信
                    </button>
                </form>
            ))}
            {second10State === 0 && (
                <p>{wrongMessage}</p>
            )}
            <br></br>
            {second10State >= 1 && (
                <div>
                    <h3 className='text'>次の3つの謎を解こう</h3>
                    <img 
                        src='/story/second/10-10.png'
                        alt='10-10'
                        className='img-normal'
                    />
                    <form onSubmit={handleSubmit21}>
                        <input
                            type='text'
                            value={userAnswer21}
                            onChange={(e) => setUserAnswer21(e.target.value)}
                            disabled={step21flag}
                            placeholder='ひらがなで入力'
                            className='sec10-answer-input'
                        />
                        <button type='submit' disabled={step21flag} style={{ backgroundColor: step21flag ? 'grey' : 'lightblue' }} className='button'>送信</button>
                    </form>
                    <br></br>
                    <img 
                        src='/story/second/10-11.png'
                        alt='10-11'
                        className='img-normal'
                    />
                    <form onSubmit={handleSubmit22}>
                        <input
                            type='text'
                            value={userAnswer22}
                            onChange={(e) => setUserAnswer22(e.target.value)}
                            disabled={step22flag}
                            placeholder='ひらがなで入力'
                            className='sec10-answer-input'
                        />
                        <button type='submit' disabled={step22flag} style={{ backgroundColor: step22flag ? 'grey' : 'lightblue' }} className='button'>送信</button>
                    </form>
                    <br></br>
                    <img 
                        src='/story/second/10-12.png'
                        alt='謎10-12'
                        className='img-normal'
                    />
                    <form onSubmit={handleSubmit23}>
                        <input
                            type='text'
                            value={userAnswer23}
                            onChange={(e) => setUserAnswer23(e.target.value)}
                            disabled={step23flag}
                            placeholder='ひらがなで入力'
                            className='sec10-answer-input'
                        />
                        <button type='submit' disabled={step23flag} style={{ backgroundColor: step23flag ? 'grey' : 'lightblue' }} className='button'>送信</button>
                    </form>
                    <br></br>
                </div>
            )}
            {second10State === 1 && (
                <p>{wrongMessage}</p>
            )}
            <br></br>
            {second10State >= 2 && (
                <div>
                    <h3 className='text'>最後の謎を解こう</h3>
                    <img 
                        src='/story/second/10-13.png'
                        alt='10-13'
                        className='img-normal'
                    />
                    <form onSubmit={handleSubmitLast}>
                        <input
                            type='text'
                            value={userAnswerLast}
                            onChange={(e) => setUserAnswerLast(e.target.value)}
                            className='sec10-answer-input'
                            placeholder='ひらがなで入力'
                            disabled={second10State === 10}
                        />
                        <button type='submit' disabled={second10State === 10} className='button'>送信</button>
                    </form>
                    <br></br>
                </div>
            )}
            {second10State === 2 && (
                <p>{wrongMessage}</p>
            )}
            {second10State === 10 && (
                clearedStory()
            )}
            <button onClick={toSecond} className='button'>2ndに戻る</button>
            <br></br>
            {/* <button onClick={toHint} className='button'>ヒント</button> */}
            <br></br>
        </div>
    )
}

export default Second10;